import React, { useState, useEffect, useContext } from "react";
import "./index.scss";
import Sidebar from "components/Sidebar";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { useTranslation } from "react-i18next";
import { UserContext } from "core/contexts";
import OrderTable from "pages/AdminMainPage/OrderTable";
import { CFormSelect } from "@coreui/react";
import UsersTable from "./../AdminStatsPage/usersTable";
import { useLocalStorage } from "utils/utils";

function AdminPartnersPage(props) {
  const { t } = useTranslation();
  const [orderStats, setOrderStats] = useState([]);
  const [userStats, setUserStats] = useState({});
  const [users, setUsers] = useState([]);
  const userContext = useContext(UserContext);
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [activeSorting, setActiveSorting] = useState(null);

  const [userSorting, setUserSorting] = useState([
    {
      name: "date",
      label: t("sorting.date"),
      value: null,
    },
  ]);
  const user = userContext.user;

  const getUsers = (pageVal, perPageVal, filtersVal, activeSortingVal) => {
    setLoading(true);
    let params = {
      page: pageVal,
      perPage: perPageVal,
    };
    if (activeSortingVal !== "sorting.null" && activeSortingVal !== null) {
      params["sort"] = [activeSortingVal];
    }
    filtersVal?.forEach((el) => {
      if (el.value && el.value !== "all") {
        if (el.type == "date") {
          let date = new Date(el.value);
          params = {
            ...params,
            [el.name]: date.toISOString(),
          };

          return;
        }
        params = { ...params, [el.name]: el.value };
      }
    });
    Axios.get(`${API_URL}/admin/partner/all`, {
      params: params,
    })
      .then(function (response) {
        setLoading(false);
        if (response.data?.data?.length > 0) {
          setUsers([]);
          setUsers(response.data.data);
          setTotal(response.data.total);
        } else {
          setUsers([]);
          setTotal(0);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <>
        <div className="admin-panel__item">
        <h6>{t("heading.partners")}</h6>
          <UsersTable
            users={users}
            setUsers={setUsers}
            getUsers={getUsers}
            total={total}
            setTotal={setTotal}
            sorting={userSorting}
            activeSorting={activeSorting}
            setSorting={setActiveSorting}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            isLoading={loading}
            partner
          />
        </div>
      </>
      {/* <div className={`page ${expanded ? "expanded" : ""}`}>
        <Sidebar />
        <div className="container container-panel">
          <div className="panel__content">
            <div className="balance admin-settings ">
              <h3>{t("heading.partners")}</h3>
            </div>
            <br />
            <div className="stats-tables user-stats">
              <UsersTable
                users={users}
                setUsers={setUsers}
                getUsers={getUsers}
                total={total}
                setTotal={setTotal}
                sorting={userSorting}

                activeSorting={activeSorting}
                setSorting={setActiveSorting}
                page={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerPage}
                isLoading={loading}
                partner
              />
            </div>
            <br />
          </div>
        </div>
      </div> */}
    </>
  );
}
export default AdminPartnersPage;
