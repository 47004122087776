import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cibVk,
  cilPhone,
  cilPen,
  cilX,
  cilCheckAlt,
  cibTelegramPlane,
  cibWhatsapp,
  cibFacebookF,
  cilXCircle,
  cibInstagram,
  cilChevronTop,
  cilCheck,
  cilListFilter,
  cilOptions,
  cilSettings,
} from "@coreui/icons";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { API_URL } from "utils/settings";
import { formatBalance, getDate, getStringDate } from "utils/utils";
import { CSpinner } from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { CTooltip, CButton } from "@coreui/react";
import TablePagination from "components/TablePagination";
import Filters from "components/Filters";
import Sorting from "components/Sorting";
import { useWindowSize } from "utils/utils";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Preloader from "components/Preloader";
import { CFormCheck } from "@coreui/react";

function CouponsTable(props) {
  let history = useHistory();

  const {
    getCoupons,
    coupons,
    total,
    page,
    setTotal,
    setCoupons,
    perPage,
    filters,
    setFilters,
    title,
    sorting,
    activeSorting,
    setSorting,
    setPage,
    setPerPage,
    isLoading,
    setLoading,
    withoutCheckboxes = false,
  } = props;
  const [deleteButton, showDeleteButton] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [count, setCount] = useState(0);

  const windowSize = useWindowSize()[0];
  const isMobile = windowSize < 767;
  const renderDate = (date) => {
    const newDate = new Date(date).toLocaleString();
    return (
      <div>
        <CTooltip content={<>{`${newDate}`}</>}>
          <div>{newDate?.split(",")[0] || "—"}</div>
        </CTooltip>
      </div>
    );
  };
  useEffect(() => {
    getCoupons(page, perPage, filters, activeSorting);
  }, [page, activeSorting]);
  useEffect(() => {
    setPage(1);
  }, [perPage, filters]);

  const { t } = useTranslation();
  const columns = [
    {
      id: "id",
      name: t("common.id"),

      selector: (row) => row.id || "—",
      sortable: false,
    },
    {
      id: "userId",
      name: t(`table.user`),

      selector: (row) => (
        <CTooltip content={<>{`${row.user.email || "—"}`}</>}>
          {row.user.id ? (
            <a href={`/admin/users/${row.user.id}`} target="_blank">
              {row.user.id || "—"}
            </a>
          ) : (
            <div>{row.user.id || "—"}</div>
          )}
        </CTooltip>
      ),
      sortable: false,
      notMobile: true,
    },

    {
      id: "balance",
      name: t("table.amount"),

      selector: (row) => {
        if (row.amount) {
          {
            return formatBalance(row.amount || 0);
          }
        } else {
          return 0;
        }
      },
      sortable: false,
    },
    {
      id: "status",
      name: t(`table.status`),

      selector: (row) => t(`table.${row.status}`),
      sortable: false,
    },
    {
      id: "adminId",
      name: t(`table.adminId`),

      selector: (row) => (
        <CTooltip content={<>{`${row.admin.email}`}</>}>
          {row.admin.id ? (
            <a href={`/admin/users/${row.admin.id}`} target="_blank">
              {row.admin.id || "—"}
            </a>
          ) : (
            <div>{row.admin.id || "—"}</div>
          )}
        </CTooltip>
      ),
      sortable: false,
      notMobile: true,
    },
    {
      id: "coupon",
      name: t("table.coupon"),

      selector: (row) => row.coupon || "—",
      sortable: false,
      mobileHeader: true,
      grow: 2,
      allowOverflow: true,
    },
    {
      id: "createDate",
      name: t("table.date_create"),

      selector: (row) => (row.createdAt ? renderDate(row.createdAt) : "—"),

      sortable: false,
      minWidth: "190px",
      notMobile: true,
    },
    {
      id: "deleteDate",
      name: t("table.date_delete"),

      selector: (row) => (row.deletedAt ? renderDate(row.deletedAt) : "—"),
      sortable: false,
      minWidth: "190px",
      notMobile: true,
    },
  ];
  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    if (selectedRows?.length > 0) {
      setRowsToDelete(selectedRows.map((el) => el.id));
    } else {
      setRowsToDelete([]);
    }
  };
  useEffect(() => {
    setToggleClearRows(!toggledClearRows);
    setRowsToDelete([]);
  }, [page]);
  useEffect(() => {
    if (rowsToDelete?.length > 0) {
      showDeleteButton(true);
    } else {
      showDeleteButton(false);
    }
  }, [rowsToDelete]);
  const clearDeletedRows = () => {};
  const deleteItems = () => {
    setLoading(true);
    Axios.delete(`${API_URL}/admin/coupon`, {
      data: {
        idList: rowsToDelete,
      },
    })
      .then(function (response) {
        if (response) {
          ToastComponent("success", t("table.coupons_is_delete"));
          setRowsToDelete([]);
          setToggleClearRows(!toggledClearRows);
          clearDeletedRows();
          getCoupons(page, perPage, filters, activeSorting);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const rowDisabledCriteria = (row) =>
    row.status == "activated" || row.status == "deleted";
  const mobileChange = (e, id) => {
    if (e.target.checked) {
      setRowsToDelete(rowsToDelete.concat(id));
    } else {
      setRowsToDelete(rowsToDelete.filter((el) => el !== id));
    }
  };

  const renderMobileItem = (el, row) => {
    return (
      <div className="mobile-list__line">
        <div className="mobile-list__key">{el.name}</div>
        <div className="mobile-list__value">{el.selector(row)}</div>
      </div>
    );
  };

  return (
    <>
      {/* <h4 className="hidden_sm hidden_md">
        {t("menu.coupons")} - {total}
      </h4> */}
      <div className="admin-panel__item">
        <div className="orders-table">
          {isLoading && (
            <div className="with-preloader">
              <Preloader />
            </div>
          )}

          {(filters?.length > 0 || sorting?.length > 0) && (
            <>
              <div className={`filters-wrapper ${showFilters ? "active" : ""}`}>
                <div className="filters-wrapper__items">
                  {filters?.length > 0 && (
                    <Filters
                      title={title || ""}
                      items={filters}
                      setFilters={(val) => {
                        setFilters(val);
                        setCount(count + 1);
                        if (page == 1 && count > 0) {
                          getCoupons(page, perPage, val, activeSorting);
                        }
                      }}
                    />
                  )}
                  {sorting?.length > 0 && coupons?.length > 0 && (
                    <>
                      <Sorting
                        items={sorting}
                        setItems={setSorting}
                        active={activeSorting}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {!isMobile ? (
            <div className="position-relative">
              {deleteButton && (
                <button
                  className="error delete-button"
                  onClick={() => deleteItems()}
                >
                  {t("table.delete")}
                </button>
              )}
              {/* {coupons?.length > 0 && ( */}
              <DataTable
                columns={columns}
                data={coupons}
                sortIcon={<CIcon icon={cilArrowTop} />}
                noDataComponent={
                  <div className="no-data">{t("table.notFound")}</div>
                }
                selectableRows={coupons?.length > 0 && !withoutCheckboxes}
                onSelectedRowsChange={(selectedRows) =>
                  coupons?.length > 0 && handleChange(selectedRows)
                }
                // selectableRowsComponent={() => (
                //   <div style={{position: 'relative'}}>
                //     <CFormCheck />
                //     <span></span>
                //   </div>
                // )}
                selectableRowsComponent={CFormCheck}
                clearSelectedRows={toggledClearRows}
                selectableRowDisabled={rowDisabledCriteria}

                // progressPending={isLoading}
                // progressComponent={<CSpinner color="primary" />}
              />
              {/* )} */}
            </div>
          ) : (
            <div className="mobile-list">
              {coupons?.length > 0 ? (
                <>
                  {coupons.map((row) => {
                    return (
                      <div className="mobile-list__item">
                        {columns.map((el) => {
                          if (el.mobileHeader) {
                            return (
                              <div className="mobile-list__header">
                                {renderMobileItem(el, row)}
                              </div>
                            );
                          }
                        })}
                        <div className="mobile-list__body">
                          {columns.map((el) => {
                            if (!el.mobileFooter && !el.mobileHeader) {
                              return renderMobileItem(el, row);
                            }
                          })}
                        </div>
                        {columns.map((el) => {
                          if (el.mobileFooter) {
                            return (
                              <div className="mobile-list__footer">
                                {renderMobileItem(el, row)}
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="no-data">{t("table.notFound")}</div>
              )}
            </div>
          )}
          {coupons?.length > 0 && (
            <TablePagination
              page={page}
              setPage={(page) => {
                setPage(page);
              }}
              total={total}
              perPage={perPage}
              setPerPage={(val) => {
                setPerPage(val);
                if (page == 1) {
                  getCoupons(page, val, filters, activeSorting);
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default CouponsTable;
