import React, {useState} from 'react';
import './index.scss';
import {
    CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CInputGroup as InputGroup,
    CInputGroupText as InputGroupText,
    CFormInput as Input,
} from "@coreui/react";
import {useTranslation} from "react-i18next";
import ModalCloseIcon from '../../assets/images/modal-close.svg'
import Logo from '../../assets/images/logo-sms.png'
import UserIcon from '../../assets/images/userForgot.svg'
import PasswordIcon from '../../assets/images/passwordAuth.svg'
import Axios from "axios";
import {API_URL} from "../../utils/settings";
import {toast} from "react-toastify";
import PasswordValidation from "../PasswordValidation";


function ForgotPasswordModal(props) {
    const {t} = useTranslation();
    const [user, setUser] = useState('')

    const {show, setShow, callback,} = props;
    const submit = (e) => {
        e.preventDefault();

        Axios.post(`${API_URL}/password/forgot`, {
            email: user,
        })
            .then(function (response, error) {
                if (response) {
                    toast.info(t("common.forgot_info"));
                }
            })
            .catch((error) => {
            });
    }

    return <CModal
        visible={show}
        onClose={() => {
            setShow(false);
        }}
    >
        <CModalHeader>
            <img src={Logo} className="modal-logo" alt=""/>
            <CModalTitle>{t("common.password_forgot")} </CModalTitle>
            <div className="modal-close" onClick={() => setShow(false)}><img src={ModalCloseIcon} alt=""/></div>
        </CModalHeader>

        <form onSubmit={(e) => submit(e)}>

            <CModalBody>
                <div className="modal-auth-form">
                    <InputGroup className="mb-3">
                        <InputGroupText id="user">
                            <img src={UserIcon} alt=""/>
                        </InputGroupText>
                        <Input
                            placeholder={t("common.username_forgot")}
                            onChange={(e) => {
                                setUser(e.target.value);
                            }}
                            required
                            type="email"
                            name={"regUser"}
                            value={user}
                        />
                    </InputGroup>

                </div>
            </CModalBody>
            <CModalFooter>
                <button
                    className="primary"
                    onClick={() => {
                        // history.replace({
                        //     search: "",
                        // });
                        // setShow(false);
                    }}
                >
                    {t("common.restore")}
                </button>
            </CModalFooter>
        </form>
    </CModal>
}

export default ForgotPasswordModal;
