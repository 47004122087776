import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar";
import MailsFromIp from "./mailsFromIp";
import MailsFromPhone from "./mailsFromPhone";
import LogsFromMail from "./logsFromMail";
import { useLocalStorage } from "utils/utils";

function LogsPage(props) {
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const tagsEmailHandler = (value) => {
    let newValue = [];
    value.forEach((el) => {
      let splitted = el.split(" ");
      if (splitted?.length === 1) {
        newValue.push(el);
      } else {
        splitted.forEach((item) => {
          if (item?.length > 0) {
            newValue.push(item);
            return;
          } else {
            return;
          }
        });
      }
    });
    return newValue;
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="logs">
        <h5>{t("ip.ip_heading")}</h5>
        <div className="admin-panel__item">
          <MailsFromIp />
        </div>
        <br />
        <h5>{t("ip.phone_heading")}</h5>
        <div className="admin-panel__item">
          <MailsFromPhone handler={tagsEmailHandler} />
        </div>
        <br />
        <h5>{t("ip.email_heading")}</h5>
        <div className="admin-panel__item">
          <LogsFromMail handler={tagsEmailHandler} />
        </div>
      </div>
    </>
  );
}

export default LogsPage;
