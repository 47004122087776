import React, { useContext, useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar";
import { CButton, CCol, CFormLabel, CRow, CSpinner } from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "core/contexts";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import Axios from "axios";
import { API_URL } from "utils/settings";
import ExportExcel from "components/ExcelExport";
import CsvDownloader from "react-csv-downloader";
import exportJson from "components/ExportJson";
import DatePicker from "react-datepicker";
import _ from "lodash";

function LogsFromMail({ handler, method = "/admin/log/list" }) {
  const [log, setLog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailsList, setEmailsList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const { t } = useTranslation();
  const logColumns = [
    {
      id: "email",
      displayName: "Email",
    },
    {
      id: "ip",
      displayName: "IP",
    },
    {
      id: "authType",
      displayName: "Auth Type",
    },
    {
      id: "createdAt",
      displayName: "Created at",
    },
    {
      id: "phone",
      displayName: "Phone",
    },
    {
      id: "orderStatus",
      displayName: "Order status",
    },
    {
      id: "country",
      displayName: "Country",
    },
    {
      id: "service",
      displayName: "Service",
    },
  ];

  const getLog = () => {
    const params = {};
    if (emailsList?.length > 0) {
      params["emailList"] = emailsList.map((el) => el.toLowerCase());
    } else {
      toast.error(t("ip.email_error"));
      return;
    }
    if (startDate) {
      params["dateFrom"] = new Date(startDate).toISOString()?.split("T")?.[0];
    }
    if (finishDate) {
      params["dateTo"] = new Date(finishDate).toISOString()?.split("T")?.[0];
    }
    setLoading(true);
    setLog([]);
    Axios.get(`${API_URL}${method}`, {
      params: params,
    })
      .then(function (response) {
        if (response.data.data) {
          const logData = response.data.data;
          const data = logData.map((el) => {
            return el.activityLogList.map((item) => {
              return {
                email: el.email,
                ...item,
              };
            });
          });
          setLog(_.flatten(data));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <div className="">
      <CCol>
        <CFormLabel>{t("ip.emails")}</CFormLabel>
        <TagsInput
          value={emailsList}
          onChange={(value) => {
            setEmailsList(handler(value));
          }}
          onBlur={(event) => {
            if (event.target.value?.length > 1) {
              setEmailsList(
                handler([...emailsList, ...event.target.value.split(" ")])
              );
              event.target.value = "";
            }
          }}
          name="emails"
          separators={["Enter", " ", "Tab"]}
        />
      </CCol>
      <br />
      <div className="ips-interval">
        <CCol xs="3">
          <CFormLabel>{t(`table.dateFrom`)}</CFormLabel>
          <input
            type="date"
            placeholderText={t("filter.datepicker")}
            showIcon
            className="form-control"
            dateFormat="dd.MM.yyyy"
            selected={startDate}
            onChange={(event) => setStartDate(event.target.value)}
          />
        </CCol>
        <span className="ips-separator">—</span>
        <CCol xs="3">
          <CFormLabel>{t(`table.dateTo`)}</CFormLabel>
          <input
            type="date"
            placeholderText={t("filter.datepicker")}
            showIcon
            className="form-control"
            dateFormat="dd.MM.yyyy"
            selected={finishDate}
            onChange={(event) => setFinishDate(event.target.value)}
          />
        </CCol>
        <div
          className="d-flex justify-content-end w-100 mt-3"
          style={{ columnGap: "15px" }}
        >
          <button
            className="primary"
            onClick={() => getLog()}
            disabled={loading}
          >
            {loading && (
              <>
                <CSpinner size="sm" color="white" />
                &nbsp;
              </>
            )}
            {t("ip.get_data")}
          </button>
          {log?.length > 0 && (
            <div className="ips-buttons">
              <CsvDownloader
                filename="log"
                extension=".csv"
                separator=";"
                wrapColumnChar="'"
                columns={logColumns}
                datas={log}
              >
                <button className="primary outline">
                  {t("ip.export_csv")}
                </button>
              </CsvDownloader>
              <ExportExcel excelData={log} fileName="log" />
              <button
                className="primary outline"
                onClick={() => exportJson(log, "log.json")}
              >
                {t("ip.export_json")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LogsFromMail;
