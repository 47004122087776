import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar";

// import OrderTable from './OrderTable';
import PaymentTable from "./PaymentTable";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import {
  CButton,
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CInputGroup as InputGroup,
  CInputGroupText as InputGroupText,
  CFormInput,
} from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "core/contexts";
import Axios from "axios";
import { API_URL } from "utils/settings";
import {
  defaultBackMonthDate,
  defaultEndDate,
  defaultStartDate,
  useLocalStorage,
} from "utils/utils";
import Layout from "components/Layout";
import classNames from "classnames";
import Preloader from "components/Preloader";
import BalanceIcon from "assets/images/balanceIcon.svg";
import ErrorIcon from "assets/images/errorIcon.svg";
import WarningIcon from "assets/images/warningIcon.svg";
import QRCode from "react-qr-code";
import Copy from "../../assets/images/copy.svg";

function BalanceHistoryPage(props) {
  const { addBalance } = props;
  const userContext = useContext(UserContext);
  const user = useContext(UserContext).user;
  const { t } = useTranslation();
  let url = new URL(window.location);
  let params = new URLSearchParams(url.search);
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [type, setType] = useState("0xprocessing");
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const location = useLocation();
  const [moneyRate, setMoneyRate] = useState(null);
  const [items, setItems] = useState([]);
  const [value, setValue] = useState(null);
  const [wallets, setWallets] = useState([]);
  const [paymentData, setPaymentData] = useState(null);
  const [active, setActive] = useState("");
  const [visible, setVisible] = useState(false);
  const getPaymentsData = () => {
    Axios.get(`${API_URL}/payment/provider/all`)
      .then(function (response) {
        if (response.data.data?.length > 0) {
          let obj = {};
          response.data.data.forEach((el) => {
            obj[el.provider] = el.commission;
          });
          setPaymentData(obj);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (addBalance) {
      getWallets();
      getInfo();
      getMoneyRates();
      getPaymentsData();
    }
  }, []);
  const getDisabled = () => {
    if (type == "0xprocessing") {
      if (!active) {
        return true;
      }
    }
    if (value?.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  const history = useHistory();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("success")) {
      ToastComponent("success", t("balance.payment_success"));
      queryParams.delete("success");
      history.replace({
        search: queryParams.toString(),
      });
    } else if (queryParams.has("failed")) {
      ToastComponent("error", t("balance.payment_failed"));
      queryParams.delete("failed");
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, []);
  const [filters, setFilters] = useState([
    {
      name: "type",
      items: ["all", "refill", "balance_correction"],
      type: "select",
      value: null,
    },
    {
      name: "statuses",
      items: ["all", "waiting", "approved", "declined", "canceled"],
      type: "multiselect",
      value: null,
    },
    {
      name: "dateFrom",
      type: "date",
      value: defaultBackMonthDate(),
    },
    {
      name: "dateTo",
      type: "date",
      value: defaultEndDate(),
    },
  ]);
  const getMoneyRates = () => {
    Axios.get(`${API_URL}/money_rate/rates`)
      .then(function (response) {
        if (response.data.rates?.length > 0) {
          setMoneyRate(response.data.rates);
        }
      })
      .catch((error) => {});
  };
  const getInfo = () => {
    Axios.get(`${API_URL}/payment/zerox/coin-info`)
      .then(function (response) {
        if (response.data.data) {
          const data = response.data.data;
          setItems(
            Object.values(data).map((el) => {
              return {
                currency: el.currency,
                minCount: el.min,
                maxCount: el.max,
                commission: el.networkFee,
                minimumWithDraw:
                  (el.networkFee * 10000000000000 + el.min * 10000000000000) /
                  10000000000000,
              };
            })
          );
        }
      })
      .catch((error) => {});
  };
  const getWallets = () => {
    Axios.get(`${API_URL}/payment/zerox/wallet`)
      .then(function (response) {
        if (response.data.data) {
          const data = response.data.data;
          setWallets(data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const createWallet = (cur) => {
    Axios.post(`${API_URL}/payment/zerox/wallet`, {
      currency: cur,
    })
      .then(function (response) {
        // if (response.data.paymentUrl) {
        //   window.location.href = response.data.paymentUrl;
        //   setTimeout(() => setLoading(false), 5000);
        // }
        if (response.data.address) {
          setIsLoading(true);
          getWallets();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const getPayments = async (reqPage, reqPerPage, reqFilters) => {
    setIsLoading(true);
    let params = {
      page: reqPage,
      perPage: reqPerPage,
    };
    console.log("req", reqFilters);
    reqFilters?.length > 0 &&
      reqFilters?.forEach((filter) => {
        if (
          (filter.value && filter.value !== "all") ||
          filter.type === "date"
        ) {
          if (filter.type === "multiselect") {
            params = { ...params, [filter.name]: [filter.value] };
            return;
          }
          if (filter.type === "date" && filter.value) {
            let date = new Date(filter.value);
            params = {
              ...params,
              [filter.name]: date.toISOString(),
            };
            return;
          }
          params = { ...params, [filter.name]: filter.value };
        }
      });

    try {
      const response = await Axios.get(`${API_URL}/payment`, {
        params,
      });
      setIsLoading(false);
      if (response.data.data?.length > 0) {
        setPayments(response.data.data);
        setTotal(response.data.total);
      } else {
        setPayments([]);
        setTotal(0);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const submitCrypto = () => {
    if (!value) {
      ToastComponent("error", t("balance.empty"));
      return;
    }
    if (+value <= 1) {
      ToastComponent("error", t("balance.min"));
      return;
    }
    if (+value > 10000) {
      ToastComponent("error", t("balance.max"));
      return;
    }
    setLoading(true);
    Axios.post(`${API_URL}/payment/zerox/refill`, {
      currency: active.currency,
      amountUsd: value.toString(),
    })
      .then(function (response) {
        if (response.data.paymentUrl) {
          window.location.href = response.data.paymentUrl;
          setTimeout(() => setLoading(false), 5000);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const submitPromo = () => {
    Axios.put(`${API_URL}/coupon/activate`, {
      coupon: value,
    })
      .then(function (response) {
        if (response) {
          ToastComponent("success", t("toast.coupon"));
          userContext.updateUser();
          userContext.updateCounter();
          setValue("");
        }
      })
      .catch((error) => {});
  };
  const submitLava = () => {
    if (value == 0) {
      ToastComponent("error", t("balance.empty"));
      return;
    }
    if (+value < 1) {
      ToastComponent("error", t("balance.min"));
      return;
    }
    setLoading(true);
    Axios.post(`${API_URL}/payment/lava/refill`, {
      amount: value.toString(),
    })
      .then(function (response) {
        if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
          setTimeout(() => setLoading(false), 5000);
        }
      })

      .catch((error) => {});
  };
  useEffect(() => {
    setValue("");
  }, [type]);
  return (
    <>
      <div className="panel__inner">
        <div
          className={classNames({
            "balance-wrapper": addBalance,
            "light-grey-block": true,
            "balance-wrapper_crypto": type == "0xprocessing" && active,
          })}
        >
          {user && addBalance && (
            <div
              className="grey-block pay-block"
              style={{
                minHeight: "unset",
                height: "unset",
                alignSelf: "flex-start",
                position: "relative",
              }}
            >
              {loading && (
                <div className="with-preloader">
                  <Preloader />
                </div>
              )}
              <h6>
                <img src={BalanceIcon} alt="" /> {t("menu.pay")}
              </h6>
              {paymentData && (
                <PaymentType
                  paymentData={paymentData}
                  type={type}
                  setType={setType}
                />
              )}
              {type == "0xprocessing" && (
                <CDropdown placement="bottom" popper={false} visible={visible}>
                  <CDropdownToggle
                    color="white"
                    onClick={() => setVisible(!visible)}
                  >
                    <div className="d-flex align-items-center">
                      {/* {activeType.inner} */}
                      {!active ? (
                        t("common.choose_crypto")
                      ) : (
                        <div className="w-100 crypto-item">
                          <div className="crypto-item__name">
                            {active.currency}
                          </div>
                          {/* <div className="crypto-item__minmax">{`${t(
                            "text.minCount"
                          )}: ${active.minCount} ${active.currency}`}</div>
                          <div className="crypto-item__minmax">{`${t(
                            "text.maxCount"
                          )}: ${active.maxCount} ${active.currency}`}</div> */}
                        </div>
                      )}
                    </div>
                  </CDropdownToggle>
                  <CDropdownMenu className="crypto-menu">
                    {items.map((el) => (
                      <div
                        className="w-100 crypto-item"
                        onClick={() => {
                          setActive(el);
                          setVisible(false);
                        }}
                      >
                        <div className="crypto-item__name">{el.currency}</div>
                        {/* <div className="crypto-item__minmax">{`${t(
                          "text.minCount"
                        )}: ${el.minCount} ${el.currency}`}</div>
                        <div className="crypto-item__minmax">{`${t(
                          "text.maxCount"
                        )}: ${el.maxCount} ${el.currency}`}</div> */}
                      </div>
                    ))}
                  </CDropdownMenu>
                </CDropdown>
              )}

              {type !== "0xprocessing" && (
                <>
                  <InputGroup>
                    <CFormInput
                      id="amount"
                      value={value}
                      type={"text"} // Всегда text для полного контроля
                      onKeyDown={(evt) => {
                        if (type !== "coupon") {
                          // Разрешаем только: цифры, точку, Backspace, Delete, стрелки
                          const allowedKeys = [
                            "0",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            ".",
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ];

                          // Проверка на повторную точку
                          const isSecondDot =
                            evt.key === "." && evt.target.value.includes(".");

                          if (!allowedKeys.includes(evt.key) || isSecondDot) {
                            evt.preventDefault();
                          }
                        }
                      }}
                      onChange={(e) => {
                        if (type !== "coupon") {
                          let input = e.target.value;

                          // Замена запятых на точки
                          input = input.replace(/,/g, ".");

                          // Удаляем все символы кроме цифр и точек
                          input = input.replace(/[^0-9.]/g, "");

                          // Обработка множественных точек
                          const parts = input.split(".");
                          if (parts.length > 2) {
                            input = parts[0] + "." + parts.slice(1).join("");
                          }

                          // Ограничение дробной части до 2 знаков
                          if (parts.length > 1) {
                            parts[1] = parts[1].slice(0, 2);
                            input = parts.join(".");
                          }

                          // Запрет на точку в начале
                          if (input === ".") input = "";

                          setValue(input);
                        } else {
                          // Для купонов - обычный текст
                          setValue(e.target.value);
                        }
                      }}
                      placeholder={
                        type !== "coupon"
                          ? t("common.payment_amount")
                          : t("common.coupon_amount")
                      }
                    />
                    {type !== "coupon" && (
                      <InputGroupText id="user">$</InputGroupText>
                    )}
                  </InputGroup>
                  <button
                    className="primary"
                    disabled={getDisabled()}
                    onClick={() => {
                      if (type == "0xprocessing") {
                        submitCrypto();
                      } else if (type == "coupon") {
                        submitPromo();
                      } else if (type == "lava") {
                        submitLava();
                      }
                    }}
                  >
                    {t("common.to_buy")}
                  </button>
                </>
              )}
            </div>
          )}
          {user && (
            <div
              className="d-flex flex-column pay-table"
              style={{ alignSelf: "flex-start" }}
            >
              {isLoading && (
                <div className="with-preloader">
                  <Preloader />
                </div>
              )}
              <PaymentTable
                currency={user.currency}
                page={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerPage}
                total={total}
                setTotal={setTotal}
                filters={filters}
                setFilters={setFilters}
                getPayments={getPayments}
                payments={payments}
              />
            </div>
          )}
          {type == "0xprocessing" && active && (
            <div className="balance-crypto grey-block">
              <h6>
                {t("common.payment_with")} {active.currency} (0xProcessing)
              </h6>
              {!wallets.some((el) => el.currency == active.currency) ? (
                <>
                  <div>
                    <div className="crypto-item__minmax">
                      {t("text.minCount")}:{" "}
                      <b>
                        {active.minimumWithDraw} {active.currency}
                      </b>
                    </div>
                    <div className="crypto-item__minmax">
                      {t("text.maxCount")}:{" "}
                      <b>
                        {active.maxCount} {active.currency}
                      </b>
                    </div>
                  </div>
                  <div>
                    <button
                      className="primary"
                      onClick={() => createWallet(active.currency)}
                    >
                      {t("wallet.create")}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="balance-alerts">
                    <div className="custom-alert custom-alert_error">
                      <img src={ErrorIcon} alt="" />
                      <div className="alert-title">
                        {t("wallet.error_title")}
                      </div>
                      <div className="alert-text">
                        {t("wallet.error_text")}
                        <br /> {t("wallet.min_amount")}:{" "}
                        <b>
                          {active.minimumWithDraw} {active.currency}
                        </b>
                      </div>
                    </div>
                    <div className="custom-alert custom-alert_warning">
                      <img src={WarningIcon} alt="" />
                      <div className="alert-title">
                        {t("wallet.warning_title")}
                      </div>
                      <div className="alert-text">
                        {t("wallet.warning_text")}
                      </div>
                    </div>
                  </div>
                  <div className="balance-wallet-info">
                    <div className="balance-wallet__qr">
                      <QRCode
                        size={216}
                        style={{
                          height: "auto",
                          maxWidth: "216px",
                          width: "216px",
                          padding: "25px",
                        }}
                        value={
                          wallets.filter(
                            (el) => el.currency == active.currency
                          )?.[0]?.address
                        }
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                    <div className="balance-wallet-info__block">
                      <div className="crypto-item__minmax">
                        {t("text.minCount")}:{" "}
                        <b>
                          {active.minimumWithDraw} {active.currency}
                        </b>
                      </div>
                      <div className="crypto-item__minmax">
                        {t("text.maxCount")}:{" "}
                        <b>
                          {active.maxCount} {active.currency}
                        </b>
                      </div>
                      <div className="crypto-item__minmax">
                        {t("wallet_com.commission_payment")}:{" "}
                        <b>{paymentData["0xprocessing"]}%</b>
                      </div>
                      <div className="crypto-item__minmax">
                        {t("wallet_com.commission_network")}:{" "}
                        <b>
                          {active.commission} {active.currency}
                        </b>
                      </div>

                      <div className="balance-wallet__input">
                        <label>
                          {t("wallet.wallet")} {active.currency}
                        </label>
                        <InputGroup>
                          <CFormInput
                            id="amount"
                            value={
                              wallets.filter(
                                (el) => el.currency == active.currency
                              )?.[0]?.address
                            }
                            readOnly
                            type={"text"} // Всегда text для полного контроля
                          />
                          <InputGroupText id="user">
                            <button
                              className="copy-icon"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  wallets.filter(
                                    (el) => el.currency == active.currency
                                  )?.[0]?.address
                                );
                                ToastComponent("success", t("copy.address"));
                              }}
                            >
                              <img src={Copy} alt="" />
                            </button>
                          </InputGroupText>
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default BalanceHistoryPage;

export const PaymentType = (props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { paymentData, type, setType } = props;

  const getCommission = (v) => {
    if (!v) return;
    return <span>{`${paymentData?.[v]}%`}</span>;
  };
  const [activeType, setActiveType] = useState({
    value: "0xprocessing",
    inner: (
      <div className="crypto-dropdown-block">
        {t("tabs.crypto")}{" "}
        <div className="com">{getCommission("0xprocessing")}</div>
      </div>
    ),
  });
  const items = [
    {
      value: "0xprocessing",
      inner: (
        <div className="crypto-dropdown-block">
          {t("tabs.crypto")}{" "}
          <div className="com">{getCommission("0xprocessing")}</div>
        </div>
      ),
    },
    {
      value: "coupon",
      inner: (
        <div className="crypto-dropdown-block">
          {t("tabs.coupon")}{" "}
          <div className="com">{getCommission("coupon")}</div>
        </div>
      ),
    },
    {
      value: "lava",
      inner: (
        <div className="crypto-dropdown-block">
          {t("commission.lava")}{" "}
          <div className="com">{getCommission("lava")}</div>
        </div>
      ),
    },
  ];
  return (
    <CDropdown placement="bottom" popper={false} visible={visible}>
      <CDropdownToggle color="white" onClick={() => setVisible(!visible)}>
        <div className="d-flex align-items-center">{activeType.inner}</div>
      </CDropdownToggle>
      <CDropdownMenu>
        {items.map((el) => (
          <div
            className="w-100"
            onClick={() => {
              setActiveType({
                value: el.value,
                inner: el.inner,
              });
              setType(el.value);
              setVisible(false);
            }}
          >
            {el.inner}
          </div>
        ))}
      </CDropdownMenu>
    </CDropdown>
  );
};
