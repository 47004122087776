import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  CButton,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroup as InputGroup,
  CInputGroupText as InputGroupText,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import { useTranslation } from "react-i18next";
import { cilCheckCircle } from "@coreui/icons";
function ProductItemLine(props) {
  const { el, changePercent, ids, setIds, percent } = props;
  const { t } = useTranslation();
  const [val, setVal] = useState(false);
  const [defaultVal, setDefaultVal] = useState(percent);
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    if (val !== defaultVal && val) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  }, [val]);

  useEffect(() => {
    if (+percent > 0) {
      setVal(percent);
    }
  }, []);

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setIds([...ids, el.id]);
    } else {
      setIds(ids.filter((item) => item !== el.id));
    }
  };

  const handleInputChange = (e) => {
    if (e.target.value.split(".")?.[1]?.length > 2) {
      return;
    }
    const val = e.target.value.replace("e", "").replace("е", "");
    if (val >= 99.99) {
      setVal(99.99);
    } else {
      setVal(e.target.value);
    }
  };
  const handleInputFocus = (e) => {
    if (e.target.value.length === 0) {
      setVal(defaultVal);
    }
  };

  const handleButtonClick = () => {
    changePercent(val, [el.id], () => {
      setShowBtn(false);
    });
  };
  return (
    <div className="partners__body-item">
      {/* <CFormCheck
        id="flexCheckChecked"
        onChange={handleCheckboxChange}
        checked={ids.includes(el.id)}
      /> */}
      <label for={`check_product_line_${el.id}`} className="auth-notice">
        <input
          type="checkbox"
          id={`check_product_line_${el.id}`}
          onChange={handleCheckboxChange}
          checked={ids.includes(el.id)}
        />
        <span className={ids.includes(el.id) ? "checked" : ""}></span>
      </label>

      <div className={`flag flag-${el.countryId}`} />
      <span>{t(`country.${el.countryId}`)}</span>
      <ItemPercent
        key={`${el.id}_id`}
        val={val}
        id={el.id}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
      />
      {showBtn && (
        <button className="partners__body-button" onClick={handleButtonClick}>
          <CIcon icon={cilCheckCircle} />
        </button>
      )}
    </div>
  );
}

function ItemPercent({ val, onChange, onFocus, id }) {
  const [value, setValue] = useState(val);

  useEffect(() => {
    setValue(val);
  }, [val]);

  return (
    <>
      {value !== 0 && (
        <CFormInput
          type="number"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-", ",", "."].includes(evt.key) &&
            evt.preventDefault()
          }
          placeholder="0-99"
          onChange={onChange}
          onBlur={onFocus}
          key={id}
          data-attr={id}
          name={id}
          value={value}
          defaultValue={value}
          style={{ minWidth: "150px" }}
        />
      )}
    </>
  );
}

export default ProductItemLine;
