import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import Sidebar from "components/Sidebar";
import { UserContext } from "core/contexts";
import { useTranslation } from "react-i18next";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CModal,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSpinner,
  CTooltip,
} from "@coreui/react";
import Axios from "axios";
import { API_URL } from "utils/settings";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import { toast } from "react-toastify";
import CIcon from "@coreui/icons-react";
import QRCode from "react-qr-code";
import TokenBlock from "./TokenBlock";
import UserForm from "./userForm";
import { generateUserInfo } from "pages/AdminUserPage/userUtils";
import { useLocalStorage } from "utils/utils";
import PrefixIgnore from "./prefixIgnore";
import Layout from "../../components/Layout";
import RulesBlock from "../../components/RulesBlock";
import { CInputGroup as InputGroup } from "@coreui/react";
import { CInputGroupText as InputGroupText } from "@coreui/react";
import LockIcon from "../../assets/images/lock.svg";
import AndroidIcon from "../../assets/images/android.png";
import PrefixImage from "../../assets/images/prefix.png";
import AppleIcon from "../../assets/images/apple.png";
import { CFormInput as Input } from "@coreui/react";
import classNames from "classnames";
import Preloader from "components/Preloader";

function SettingsPage(props) {
  const userContext = useContext(UserContext);
  const user = userContext.user;
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const [show2fa, setShow2fa] = useState(false);
  const [code, setCode] = useState("");
  const [qr, setQR] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const toggleTFA = (val) => {
    if (
      userContext.user.twoFactorEnabled &&
      userContext.user.twoFactorConfirmed
    ) {
      setShow2fa(true);
    } else if (
      !userContext.user.twoFactorEnabled &&
      !userContext.user.twoFactorConfirmed
    ) {
      setLoading(true);
      Axios.post(`${API_URL}/user/2fa/state`)
        .then(function (response) {
          userContext.updateUser();
        })
        .catch((error) => {});
    }
  };
  const confirm2FA = (v) => {
    Axios.post(`${API_URL}/user/2fa/confirm`, {
      oneTimeCode: v,
    })
      .then(function (response) {
        userContext.updateUser();
      })
      .catch((error) => {});
  };
  const cancel2fa = () => {
    Axios.delete(`${API_URL}/user/2fa/state`)
      .then(function (response) {
        userContext.updateUser();
        setQR(null);
        setCode("");
        setShow2fa(false);
      })
      .catch((error) => {});
  };
  const getQR = () => {
    Axios.get(`${API_URL}/user/2fa/qr-code`).then(function (response) {
      setQR(response.data.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    if (!qr && user?.twoFactorEnabled) {
      getQR();
    }
  }, [user]);
  const getUserInfo = (val) => {
    let data = {};
    let newData = {
      email: user.email,
      balance: user.balance,
      currency: user.currency,
      frozenBalance: user.frozenBalance,
    };
    if (user?.partner?.isActive && user?.partner?.hash) {
      newData["hash"] = user?.partner?.hash;
    }
    return generateUserInfo(newData, data);
  };

  return (
    <div className="panel__inner">
      <div className="light-grey-block">
        <div className="columns__item shadow-block orders-table">
          <CModal
            backdrop="static"
            visible={show2fa}
            onClose={() => setShow2fa(false)}
          >
            <CModalHeader>
              <CModalTitle>{t("2fa.cancel")}</CModalTitle>
            </CModalHeader>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setShow2fa(false)}>
                {t("common.no")}
              </CButton>
              <CButton color="primary" onClick={() => cancel2fa()}>
                {t("common.yes")}
              </CButton>
            </CModalFooter>
          </CModal>

          <div
            className={classNames({
              settings: true,
            })}
          >
            {loading && (
              <div className="with-preloader">
                <Preloader />
              </div>
            )}

            <div className="userdata">
              <h6>{t("common.email_password")}</h6>
              <InputGroup className="mb-3">
                <Input
                  placeholder={t("common.username")}
                  onChange={(e) => {
                    // setUser(e.target.value);
                  }}
                  name="user"
                  required
                  type="email"
                  disabled={true}
                  value={user?.email}
                />
                <InputGroupText id="user">
                  <img src={LockIcon} alt="" />
                </InputGroupText>
              </InputGroup>
              <PasswordRestore />
            </div>
            <div className="twofa">
              <h6>{t("2fa.enable")}</h6>

              <InputGroup className="mb-3">
                <Input
                  placeholder={
                    user?.twoFactorConfirmed ? t("common.on") : t("common.off")
                  }
                  onChange={(e) => {
                    // setUser(e.target.value);
                  }}
                  name="user"
                  required
                  type="email"
                  disabled={true}
                  value={
                    user?.twoFactorConfirmed ? t("common.on") : t("common.off")
                  }
                />
                <InputGroupText id="user">
                  <img src={LockIcon} alt="" />
                </InputGroupText>
              </InputGroup>
              <h3>
                {user?.twoFactorConfirmed
                  ? t("2fa.notify_about_qr_off")
                  : t("2fa.notify_about_qr")}
              </h3>
              <div className="qr-wrapper">
                {!user?.twoFactorConfirmed ? (
                  <>
                    <div
                      className={classNames({
                        "qr-code": true,
                        disabled: true,
                      })}
                    >
                      {qr ? (
                        <img src={qr} width="165px" />
                      ) : (
                        <>
                          <QRCode
                            size={165}
                            style={{
                              height: "auto",
                              maxWidth: "165px",
                              width: "165px",
                              padding: "10px",
                            }}
                            value={
                              "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share"
                            }
                            viewBox={`0 0 256 256`}
                          />
                          <div className="qr-code__block">
                            <button
                              className={"primary"}
                              onClick={() => toggleTFA(true)}
                            >
                              {t("2fa.get")}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <h4>{t("common.type.your_code")}</h4>
                      <Input
                        type="number"
                        id="inputPassword2"
                        placeholder={t("common.your_code")}
                        value={code}
                        required
                        maxLength="6"
                        onChange={(e) => {
                          setCode(e.target.value);
                          if (e.target.value?.length == 6 && qr) {
                            confirm2FA(e.target.value);
                          }
                        }}
                      />
                      <div className="settings__stores">
                        <CTooltip
                          content={
                            <div>
                              {" "}
                              <QRCode
                                size={256}
                                style={{
                                  height: "auto",
                                  maxWidth: "100%",
                                  width: "100%",
                                  padding: "10px",
                                }}
                                value={
                                  "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share"
                                }
                                viewBox={`0 0 256 256`}
                              />
                            </div>
                          }
                          placement="top"
                        >
                          <a
                            target="_blank"
                            //   variant="outline"
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share"
                          >
                            <img src={AndroidIcon} alt="" />
                          </a>
                        </CTooltip>
                        <CTooltip
                          content={
                            <div>
                              {" "}
                              <QRCode
                                size={256}
                                style={{
                                  height: "auto",
                                  maxWidth: "100%",
                                  width: "100%",
                                  padding: "10px",
                                }}
                                value={
                                  "https://apps.apple.com/ru/app/google-authenticator/id388497605"
                                }
                                viewBox={`0 0 256 256`}
                              />
                            </div>
                          }
                          placement="top"
                        >
                          <a
                            target="_blank"
                            href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                          >
                            <img src={AppleIcon} alt="" />
                          </a>
                        </CTooltip>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="two-fa-off">
                    <div>
                      {/* <h4>{t("common.type.your_code")}</h4> */}
                      <div
                        className="d-flex justify-content-end"
                        style={{ columnGap: "15px" }}
                      >
                        {/* <Input
                        type="number"
                        id="inputPassword2"
                        placeholder={t("common.your_code")}
                        value={code}
                        required
                        maxLength="6"
                        onChange={(e) => {
                          setCode(e.target.value);
                        }}
                      /> */}
                        <button className="primary" onClick={() => cancel2fa()}>
                          {t("common.turn_off")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="token">
              <TokenBlock />
            </div>
          </div>
        </div>
        <div
          className="grey-block prefix"
          style={{ marginTop: "16px", minHeight: "unset" }}
        >
          <PrefixIgnore />
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;

export const PasswordRestore = () => {
  const [oldPass, setOldPass] = useState();
  const [newPass, setNewPass] = useState();
  const [confirmedPass, setConfirmedPass] = useState();
  const { t } = useTranslation();

  return (
    <div className={"password-restore"}>
      <h6>{t("common.change_password")}</h6>

      <InputGroup className="mb-3">
        <Input
          placeholder={t("common.old_password")}
          onChange={(e) => {
            setOldPass(e.target.value);
          }}
          name="user"
          required
          type="text"
          value={oldPass}
          disabled={true}
        />
        <InputGroupText id="user">
          <img src={LockIcon} alt="" />
        </InputGroupText>
      </InputGroup>
      <InputGroup className="mb-3">
        <Input
          placeholder={t("common.new_password")}
          onChange={(e) => {
            setNewPass(e.target.value);
          }}
          name="user"
          required
          type="text"
          value={newPass}
          disabled={true}
        />
        <InputGroupText id="user">
          <img src={LockIcon} alt="" />
        </InputGroupText>
      </InputGroup>
      <InputGroup className="mb-3">
        <Input
          placeholder={t("common.confirm_password")}
          onChange={(e) => {
            setConfirmedPass(e.target.value);
            // setUser(e.target.value);
          }}
          name="user"
          required
          type="text"
          value={confirmedPass}
          disabled={true}
        />
        <InputGroupText id="user">
          <img src={LockIcon} alt="" />
        </InputGroupText>
      </InputGroup>
      <button className="primary" disabled>
        {t("common.change_password")}
      </button>
    </div>
  );
};
