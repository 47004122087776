import classNames from "classnames";
import AdminSidebar from "components/AdminSidebar";
import Footer from "components/Footer";
import Header from "components/Header";
import "./index.scss";
import Sidebar from "components/Sidebar";
import { UserContext } from "core/contexts";
import ServicesList from "pages/AdminMainPage/ServiceList";
import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useLocalStorage, useWindowSize } from "utils/utils";

function Layout(props) {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");

  const windowSize = useWindowSize()[0];
  const isMobile = windowSize < 991;
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [show2faModal, setShow2faModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [needRedirect, setNeedRedirect] = useState(true);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);
  useEffect(() => {
    if (document.querySelector(".panel-wrapper")) {
      isMobile && document.querySelector(".panel-wrapper").scrollIntoView();
    } else if (document.querySelector(".admin-panel__content")) {
      document.querySelector(".admin-panel__content").scrollTo(0, 0);
      if (isMobile) {
        window.scrollTo(0,0)
      }
    }
  }, [location.pathname]);
  return (
    <>
      {!location.pathname?.includes("admin") ? (
        <>
          <Header
            authorized={props.authorized}
            showAuthModal={showAuthModal}
            setShowAuthModal={setShowAuthModal}
            show2faModal={show2faModal}
            setShow2faModal={setShow2faModal}
            showResetModal={showResetModal}
            setShowResetModal={setShowResetModal}
            showRegisterModal={showRegisterModal}
            setShowRegisterModal={setShowRegisterModal}
            showForgotModal={showForgotModal}
            setShowForgotModal={setShowForgotModal}
            needRedirect={needRedirect}
            setNeedRedirect={setNeedRedirect}
          />
          <div
            className={classNames({
              container: !isMobile,
            })}
          >
            <div className="panel__content">
              <div className="columns columns_sm">
                <ServicesList
                  updateUser={UserContext.updateUser}
                  authorized={props.authorized}
                  showAuthModal={showAuthModal}
                  setShowAuthModal={setShowAuthModal}
                  showRegisterModal={showRegisterModal}
                  setShowRegisterModal={setShowRegisterModal}
                  showForgotModal={showForgotModal}
                  setShowForgotModal={setShowForgotModal}
                  setNeedRedirect={setNeedRedirect}
                />
                <div
                  className={classNames({
                    container: isMobile,
                    "panel-wrapper": true,
                  })}
                >
                  {props.children}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={`admin-panel ${!expanded ? "expanded" : ""}`}>
          <div className="admin-panel__layout">
            <AdminSidebar expanded={expanded} />

            <div className="admin-panel__content">
              <>{props.children}</>
            </div>
          </div>
        </div>
      )}
      <Footer authorized={props.authorized} />
    </>
  );
}

export default Layout;
