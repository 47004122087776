import React, { useState } from "react";
import "./index.scss";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CInputGroup as InputGroup,
  CInputGroupText as InputGroupText,
  CFormInput as Input,
  CFormCheck,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import ModalCloseIcon from "../../assets/images/modal-close.svg";
import Logo from "../../assets/images/logo-sms.png";
import UserIcon from "../../assets/images/userAuth.svg";
import PasswordIcon from "../../assets/images/passwordAuth.svg";
import Axios from "axios";
import { API_URL } from "../../utils/settings";
import { toast } from "react-toastify";
import PasswordValidation from "../PasswordValidation";
import ViewIcon from "../../assets/images/eye.svg";

function RegisterModal(props) {
  const { t } = useTranslation();
  const [user, setUser] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [checked, setChecked] = useState(false);
  const [show2fa, setShow2fa] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [password, setPassword] = useState("");
  const { show, setShow, callback, showAuthModal, setShowAuthModal } = props;
  const submit = (e) => {
    e.preventDefault();
    if (
      !passwordIsValid ||
      /[^\w\-!$%^&*()_+|~={}\[\]:\/;<>?,.@#№]/.test(password)
    ) {
      toast.info(t("password.valid"));
    } else {
      Axios.post(`${API_URL}/register`, {
        email: user,
        password: password,
        currency: "USD",
      })
        .then(function (response) {
          if (response) {
            // showMailNotify(true);
            toast.info(t("register.notify"));
          }
        })
        .catch((error) => {});
    }
  };
  const generatePass = (setValue) => {
    setValue("");
    const min = 10;
    const max = 12;
    const passwordLength = Math.floor(Math.random() * (max - min + 1) + min);

    let passwordNew = generatePassword(passwordLength);
    do {
      passwordNew = generatePassword(passwordLength).replace(/['`"]+/g, "");
    } while (passwordNew?.length < min);
    setValue(passwordNew);
    navigator.clipboard.writeText(passwordNew);
    toast.success(t("password.is_generated"));
  };
  return (
    <CModal
      visible={show}
      onClose={() => {
        setShow(false);
      }}
    >
      <CModalHeader>
        <img src={Logo} className="modal-logo" alt="" />
        <CModalTitle>
          {t("common.registration")}{" "}
          <span
            className="modal-link"
            onClick={() => {
              setShow(false);
              setShowAuthModal(true);
            }}
          >
            {t("heading.have_account")}
          </span>
        </CModalTitle>
        <div className="modal-close" onClick={() => setShow(false)}>
          <img src={ModalCloseIcon} alt="" />
        </div>
      </CModalHeader>

      <form onSubmit={(e) => submit(e)}>
        <CModalBody>
          <div className="message message_warning">{t("register.alert")}</div>
          <div className="modal-auth-form">
            <InputGroup className="mb-3">
              <InputGroupText id="user">
                <img src={UserIcon} alt="" />
              </InputGroupText>
              <Input
                placeholder={t("common.username")}
                onChange={(e) => {
                  setUser(e.target.value);
                }}
                required
                type="email"
                name={"regUser"}
                value={user}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupText id="user">
                <img src={PasswordIcon} alt="" />
              </InputGroupText>
              <Input
                placeholder={t("common.password")}
                onChange={(e) => {
                  setPassword(e.target.value);
                  // if (loginMailNotify) {
                  //     showLoginMailNotify(false);
                  // }
                }}
                value={password}
                required
                type={showPass ? "text" : "password"}
                name={"regPass"}
                // autoComplete={false}
                autoComplete="off"
                style={{ paddingRight: "0" }}
              />

              <InputGroupText>
                <div
                  className="generate-password"
                  onClick={() => generatePass(setPassword)}
                >
                  {t("password.generate")}
                </div>
                <div
                  onClick={() => setShowPass(!showPass)}
                  className={`icon-eye ${showPass ? "active" : ""}`}
                >
                  <img src={ViewIcon} alt="" />
                </div>
              </InputGroupText>
            </InputGroup>
            <PasswordValidation
              value={password}
              setPasswordValidation={setPasswordIsValid}
            />

            <label for="agreeCheck" className="auth-notice">
              <input
                type="checkbox"
                id="agreeCheck"
                onChange={() => setChecked(!checked)}
                checked={checked}
              />
              <span className={checked ? "checked" : ""}></span>
              <div>
                {t("auth.notice")}{" "}
                <a href="/rules" target="_blank">
                  {t("auth.agree")}
                </a>
              </div>
            </label>
          </div>
        </CModalBody>
        <CModalFooter>
          <button
            className="primary"
            disabled={!checked}
            onClick={() => {
              // history.replace({
              //     search: "",
              // });
              // setShow(false);
            }}
          >
            {t("front_page.body.register")}
          </button>
        </CModalFooter>
      </form>
    </CModal>
  );
}

export default RegisterModal;

function generatePassword(length) {
  if (length < 4) {
    throw new Error("Длина пароля должна быть не менее 4 символов.");
  }

  const numbers = "0123456789";
  const lowerCase = "abcdefghijklmnopqrstuvwxyz";
  const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const specialChars = "!@#$%^&*()-_=+[]{}|;:',.<>?/~`";

  // Функция для выбора случайного символа из строки
  const getRandomChar = (chars) =>
    chars[Math.floor(Math.random() * chars.length)];

  // Генерируем по одному символу из каждого обязательного правила
  const passwordComponents = [
    getRandomChar(numbers),
    getRandomChar(lowerCase),
    getRandomChar(upperCase),
    getRandomChar(specialChars),
  ];

  // Остальные символы заполняются случайно из всех возможных
  const allChars = numbers + lowerCase + upperCase + specialChars;
  while (passwordComponents.length < length) {
    passwordComponents.push(getRandomChar(allChars));
  }

  // Перемешиваем массив символов для случайного порядка
  for (let i = passwordComponents.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [passwordComponents[i], passwordComponents[j]] = [
      passwordComponents[j],
      passwordComponents[i],
    ];
  }

  // Возвращаем сгенерированный пароль
  return passwordComponents.join("");
}
