import React, { useState, useEffect } from "react";
import "./index.scss";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { useTranslation } from "react-i18next";
import {
  CButton,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import {
  cnCountries,
  enCountries,
  esCountries,
  ruCountries,
} from "components/ChooseCountries/countries";
import Select from "react-select";
import { useLocalStorage } from "utils/utils";
import { cilPencil, cilX } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import ToastComponent from "components/ToastComponent";
import PrefixImage from "../../assets/images/prefix.png";
import PrefixEdit from "../../assets/images/prefixEdit.svg";
import PrefixDelete from "../../assets/images/prefixClose.svg";
import ModalCloseIcon from "../../assets/images/modal-close.svg";
import Logo from "../../assets/images/logo-sms.png";
function PrefixIgnore(props) {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [show, setShow] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [locale] = useLocalStorage("locale", "gb");
  const [loading, setLoading] = useState(false);
  const [prefixes, setPrefixes] = useState({});
  const [defaultValue, setDefaultValue] = useState();
  const [editId, setEditId] = useState("");
  const [editPrefix, setEditPrefix] = useState("");
  const [type, setType] = useState("add");
  const countriesObj = {
    ru: ruCountries,
    gb: enCountries,
    cn: cnCountries,
    es: esCountries,
  };
  const actualLanguage = countriesObj[locale];
  useEffect(() => {
    getPrefix();
    getCountries();
  }, []);
  const getPrefix = () => {
    Axios.get(`${API_URL}/phone/prefix-ignore`)
      .then(function (response) {
        if (response.data) {
          const data = response.data.data;
          if (data?.legnth == 0) {
            setPrefixes({});
            return;
          }
          let obj = {};
          data.map((el) => {
            if (obj.hasOwnProperty(el.countryId)) {
              obj[el.countryId] = obj[el.countryId].concat(el);
            } else {
              obj[el.countryId] = [el];
            }
          });
          setPrefixes(obj);
        }
      })
      .catch((error) => {});
  };
  const getCountries = () => {
    Axios.get(`${API_URL}/country`)
      .then(function (response) {
        if (response.data.data?.length > 0) {
          setCountries(response.data.data.filter((el) => el.phonePrefix));
        }
      })
      .catch((error) => {});
  };
  const deletePrefix = (id) => {
    setLoading(true);
    Axios.delete(`${API_URL}/phone/prefix-ignore`, {
      data: {
        id: id,
      },
    })
      .then(function (response) {
        if (response) {
          ToastComponent("success", t("prefix.delete"));
          getPrefix();
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <>
      <div className="prefix-header d-flex align-items-center ">
        <img src={PrefixImage} alt="" />
        <div>
          <h5>{t("prefix.heading")}</h5>
          <p>{t("prefix.subheading")}</p>
          <button
            className="primary"
            onClick={() => {
              setType("add");
              setShow(true);
              setEditId();
              setEditPrefix();
            }}
          >
            {t("prefix.add")}
          </button>
        </div>
      </div>
      {Object.entries?.(prefixes)?.length > 0 && (
        <div className="prefix__body">
          <h6>{t("prefix.list")}</h6>

          <div className="prefix-list">
            {Object.entries(prefixes).map((el) => {
              return (
                <CDropdown  placement="top">
                  <CDropdownToggle color="white">
                    <div className="d-flex align-items-center">
                      <div className={`flag flag-${el[0]}`} />{" "}
                      <b>
                        <span className="prefix-country-name">
                          {actualLanguage[el[0]]}
                        </span>{" "}
                        +{el[1][0].countryPhonePrefix}
                      </b>
                    </div>
                  </CDropdownToggle>
                  <CDropdownMenu>
                    {el[1].map((p) => {
                      return (
                        <CDropdownItem key={p.id} className="prefix-item">
                          <div className="prefix-item__start">
                            <div className={`flag flag-${p.countryId}`} />{" "}
                            <b>
                              +{p.countryPhonePrefix}&nbsp;
                              <span>{p.phonePrefixIgnore}</span>
                            </b>
                          </div>
                          <div className="prefix-item__end">
                            <button
                              disabled={loading}
                              onClick={() => {
                                setType("edit");
                                setShow(true);
                                setDefaultValue({
                                  label: (
                                    <div className="prefix-item-item">
                                      <div
                                        className={`flag flag-${p.countryId}`}
                                      />{" "}
                                      +{p.countryPhonePrefix}
                                    </div>
                                  ),
                                  value: p.countryId,
                                });
                                setEditId(p.id);
                                setEditPrefix(p.phonePrefixIgnore);
                              }}
                            >
                              <img src={PrefixEdit} alt="" />
                            </button>
                            <button
                              disabled={loading}
                              onClick={() => deletePrefix(p.id)}
                            >
                              <img src={PrefixDelete} alt="" />
                            </button>
                          </div>
                        </CDropdownItem>
                      );
                    })}
                  </CDropdownMenu>
                </CDropdown>
              );
            })}
          </div>
        </div>
      )}
      <PrefixModal
        show={show}
        callback={() => {
          setShow(false);
          getPrefix();
        }}
        type={type}
        countries={countries}
        setShow={setShow}
        editId={editId}
        defaultValue={defaultValue}
        editPrefixValue={editPrefix}
      />
    </>
  );
}

export default PrefixIgnore;

function PrefixModal(props) {
  const { t } = useTranslation();
  const {
    setShow,
    show,
    type,
    countries,
    callback,
    defaultValue,
    editId,
    editPrefixValue,
  } = props;
  const [value, setValue] = useState();
  const [selectValue, setSelectValue] = useState("");
  const submit = (e) => {
    e.preventDefault();
    type == "add" ? addPrefix() : editPrefix();
  };
  useEffect(() => {
    setValue("");
  }, [show]);
  useEffect(() => {
    if (editPrefixValue && type == "edit") {
      setValue(editPrefixValue);
    }
  }, [editPrefixValue]);
  const addPrefix = () => {
    Axios.post(`${API_URL}/phone/prefix-ignore`, {
      countryId: selectValue.label.props.children[0]?.props.className
        .split(" ")[1]
        ?.split("-")[1],
      phonePrefix: +value,
    })
      .then(function (response, error) {
        if (response) {
          ToastComponent("success", t("prefix.added"));
          callback();
        }
      })
      .catch((error) => {});
  };
  const editPrefix = () => {
    Axios.put(`${API_URL}/phone/prefix-ignore`, {
      id: editId,
      countryId: defaultValue.value,
      phonePrefix: +value,
    })
      .then(function (response, error) {
        if (response) {
          ToastComponent("success", t("prefix.edited"));

          callback();
        }
      })
      .catch((error) => {});
  };
  const isDisabledButton = () => {
    if (type == "edit" && value == editPrefixValue) {
      return true;
    } else if (!value) {
      return true;
    } else if (!selectValue && type == "add") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <CModal visible={show} onClose={() => setShow(false)}>
      <CModalHeader>
        <img src={Logo} className="modal-logo" alt="" />
        <CModalTitle>
          {type == "add" ? t("prefix.add") : t("prefix.edit")}
        </CModalTitle>
        <div className="modal-close" onClick={() => setShow(false)}>
          <img src={ModalCloseIcon} alt="" />
        </div>
      </CModalHeader>
      <form className="prefix-modal" onSubmit={(e) => submit(e)}>
        <CModalBody>
          <CInputGroup>
            <CInputGroupText style={{ minWidth: "177px" }}>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isSearchable
                name="prefix"
                defaultValue={type == "edit" && defaultValue}
                isDisabled={type == "edit"}
                placeholder=""
                options={countries.map((el) => {
                  return {
                    label: (
                      <div className="prefix-item-item">
                        <div className={`flag flag-${el.id}`} />{" "}
                        {`+${el.phonePrefix}`}
                      </div>
                    ),
                    value: `+${el.phonePrefix}`,
                  };
                })}
                onChange={(value) => {
                  setValue("");
                  setSelectValue(value);
                }}
              />
            </CInputGroupText>
            <CFormInput
              value={value}
              type="number"
              placeholder={t("common.numbers_after_code")}
              defaultValue={editPrefixValue}
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ",", "."].includes(evt.key) &&
                evt.preventDefault()
              }
              onChange={(e) => {
                let length = e.target.value?.length;
                if (type == "add") {
                  if (selectValue?.value) {
                    length =
                      length + selectValue.value?.replace("+", "")?.length;
                  }
                } else if (type == "edit") {
                  if (defaultValue?.label) {
                    length =
                      length +
                      defaultValue?.label?.props?.children[
                        defaultValue?.label?.props?.children?.length - 1
                      ].toString().length;
                  }
                }
                if (length < 7) {
                  setValue(e.target.value);
                }
              }}
            />
          </CInputGroup>
        </CModalBody>
        <CModalFooter>
          <button
            className="primary"
            disabled={isDisabledButton()}
            type="submit"
          >
            {t("common.add")}
          </button>
        </CModalFooter>
      </form>
    </CModal>
  );
}
