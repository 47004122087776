import React, { useState } from "react";
import {
  CButton,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CInputGroupText,
} from "@coreui/react";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { useTranslation } from "react-i18next";
import { formatBalance, renderCurrency } from "utils/utils";
import ToastComponent from "components/ToastComponent";
import ModalCloseIcon from "../../assets/images/modal-close.svg";

function PayoutModal(props) {
  const { show, setShow, balance, id, currency, callback } = props;
  const { t } = useTranslation();
  const [val, setVal] = useState(null);
  const submit = (e) => {
    e.preventDefault();
    Axios.put(`${API_URL}/admin/partner/${id}/payout`, {
      amount: val,
    })
      .then(function (response) {
        if (response) {
          callback();
          ToastComponent("success", t("payout.success"));
          setShow(false);
        }
      })
      .catch((error) => {});
  };
  return (
    <CModal visible={show} onClose={() => setShow(false)}>
      <CModalHeader>
        <CModalTitle>{t("heading.payout")}</CModalTitle>
        <div className="modal-close" onClick={() => setShow(false)}>
          <img src={ModalCloseIcon} alt="" />
        </div>
      </CModalHeader>
      <form onSubmit={(e) => submit(e)}>
        <CModalBody>
          {currency && (
            <p className="balance-block">
              {t("common.balance")}: {formatBalance(balance)}
            </p>
          )}
          <div>
            <CFormLabel>{t("table.amount")}</CFormLabel>
            <CInputGroup>
              <CFormInput
                type="number"
                placeholder={t("table.amount")}
                value={val}
                step="any"
                required
                defaultValue={val}
                onChange={(e) => {
                  if (e.target.value.split(".")?.[1]?.length > 4) {
                    return;
                  }
                  if (e.target.value > balance) {
                    setVal(parseFloat(balance)?.toFixed(4));
                  } else {
                    setVal(e.target.value);
                  }
                }}
              />
              <CInputGroupText>{renderCurrency(currency)}</CInputGroupText>
            </CInputGroup>
          </div>
        </CModalBody>
        <CModalFooter>
          <button className="primary" type="submit">
            {t("common.submit")}
          </button>
        </CModalFooter>
      </form>
    </CModal>
  );
}
export default PayoutModal;
