import Axios from "axios";
import { API_URL } from "utils/settings";

export const renderRole = (name) => {
  const obj = {
    isAdmin: "ROLE_ADMIN",
    isSupport: "ROLE_SUPPORT",
  };
  return obj[name];
};

export const deactivateUser = (value, isLoading, setLoading, callback, id) => {
  setLoading(true);

  Axios.put(`${API_URL}/admin/user/${id}/activate`, {
    state: value.target.checked ? 1 : 0,
  })
    .then((res) => {
      callback();
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
    });
};
export const deactivateRestriction = (
  value,
  isLoading,
  setLoading,
  callback,
  id
) => {
  setLoading(true);

  Axios.put(`${API_URL}/admin/restriction`, {
    state: value.target.checked ? 1 : 0,
    restriction: "activate_coupon",
    userId: +id,
  })
    .then((res) => {
      callback();
      // setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
    });
};
export const deactivatePartner = (
  value,
  isLoading,
  setLoading,
  callback,
  id
) => {
  setLoading(true);

  Axios.put(`${API_URL}/admin/partner/${id}/activate`, {
    state: value.target.checked ? 1 : 0,
  })
    .then((res) => {
      callback();
      // setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
    });
};

export const changeToggle = (item, value, setLoading, callback, id) => {
  if (value.target.checked) {
    setRole(item.name, setLoading, callback, id);
  } else {
    deleteRole(item.name, setLoading, callback, id);
  }
};

export const setRole = (role, setLoading, callback, id) => {
  setLoading(true);
  Axios.put(`${API_URL}/admin/user/${id}/role`, {
    role: renderRole(role),
  })
    .then((res) => {
      callback();
      // setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
    });
};
export const deleteRole = (role, setLoading, callback, id) => {
  setLoading(true);
  Axios.delete(`${API_URL}/admin/user/${id}/role`, {
    data: {
      role: renderRole(role),
    },
  })
    .then((res) => {
      callback();
      // setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
    });
};
