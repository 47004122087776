import React, { useState } from "react";
import "./index.scss";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CInputGroup as InputGroup,
  CInputGroupText as InputGroupText,
  CFormInput as Input,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import ModalCloseIcon from "../../assets/images/modal-close.svg";
import Logo from "../../assets/images/logo-sms.png";
import UserIcon from "../../assets/images/userAuth.svg";
import PasswordIcon from "../../assets/images/passwordAuth.svg";
import Axios from "axios";
import { API_URL } from "../../utils/settings";
import { toast } from "react-toastify";
import ToastComponent from "components/ToastComponent";

function AuthModal(props) {
  const { t } = useTranslation();
  const [user, setUser] = useState("");
  const [show2fa, setShow2fa] = useState(false);
  const [password, setPassword] = useState("");
  const {
    show,
    setShow,
    callback,
    setShowRegisterModal,
    setShowForgotModal,
    needRedirect,
    setShow2faModal,
  } = props;
  const submit = (e) => {
    e.preventDefault();

    Axios.post(`${API_URL}/auth/login_check`, {
      email: user,
      password: password,
    })
      .then(function (response, error) {
        if (response?.data?.emailConfirmation === false) {
          ToastComponent("info", t("register.login_notify"));
          // showLoginMailNotify(true);
          return;
        }
        if (response?.data?.token) {
          localStorage.setItem("token", response.data.token);
          setShow(false);
          // if (needRedirect) {
          window.location.href = "/panel/main";
          // }
        } else if (
          response?.data?.login == "success" &&
          response?.data?.twoFactorComplete === false
        ) {
          setShow(false);
          setShow2faModal(true);
          setShow2fa(true);
        }
      })
      .catch((error) => {});
  };
  return (
    <CModal
      visible={show}
      onClose={() => {
        setShow(false);
      }}
    >
      <CModalHeader>
        <img src={Logo} className="modal-logo" alt="" />
        <CModalTitle>
          {t("heading.auth")}{" "}
          <span
            className="modal-link"
            onClick={() => {
              setShow(false);
              setShowRegisterModal(true);
            }}
          >
            {t("front_page.body.register")}
          </span>
        </CModalTitle>
        <div className="modal-close" onClick={() => setShow(false)}>
          <img src={ModalCloseIcon} alt="" />
        </div>
      </CModalHeader>
      <form onSubmit={(e) => submit(e)}>
        <CModalBody>
          <div className="modal-auth-form">
            <InputGroup className="mb-3">
              <InputGroupText id="user">
                <img src={UserIcon} alt="" />
              </InputGroupText>
              <Input
                placeholder={t("common.username")}
                onChange={(e) => {
                  setUser(e.target.value);
                }}
                name="user"
                required
                type="email"
                autoComplete
                value={user}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupText id="user">
                <img src={PasswordIcon} alt="" />
              </InputGroupText>
              <Input
                placeholder={t("common.password")}
                onChange={(e) => {
                  setPassword(e.target.value);
                  // if (loginMailNotify) {
                  //     showLoginMailNotify(false);
                  // }
                }}
                value={password}
                name="password"
                required
                type="password"
                autoComplete
              />
            </InputGroup>
          </div>
        </CModalBody>
        <CModalFooter>
          <span
            className="modal-link"
            onClick={() => {
              setShow(false);
              setShowForgotModal(true);
            }}
          >
            {t("common.forgot")}
          </span>
          <button
            className="primary"
            onClick={() => {
              // history.replace({
              //     search: "",
              // });
              // setShow(false);
            }}
          >
            {t("heading.auth_action")}
          </button>
        </CModalFooter>
      </form>
    </CModal>
  );
}

export default AuthModal;
