import React from 'react';
import './index.scss';
import {cilAccountLogout, cilBarChart, cilDescription, cilListRich, cilPlus, cilSettings} from "@coreui/icons";
import {withTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import classNames from "classnames";

function RoutingTabs({t}) {
    const location = useLocation();

    const links = [
        {
            label: t("heading.orders"),
            link: "/panel/main",
        },
        {
            label: t("menu.pay"),
            link: "/panel/balance",
        },
        {
            label: t("common.history"),
            link: "/panel/history",
        },
        {
            label: t("menu.admin_settings"),
            link: "/panel/settings/",
        },
        {
            label: t("nav.menu.rule"),
            link: "/panel/rules/",
        },
    ]
    return <div className="routing-tabs">
        {links.map((element, index) => (
            <Link key={index} className={classNames({
                "active": location.pathname.includes(element.link) ||
                    location.pathname == element.link,
            })} to={`${element.link}`}>{element.label}</Link>
        ))}
    </div>
}

export default withTranslation()(RoutingTabs)