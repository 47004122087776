import React from "react";
import "./index.scss";
import { CButton, CButtonGroup, CFormLabel, CFormSelect } from "@coreui/react";
import { useTranslation } from "react-i18next";
import CIcon from "@coreui/icons-react";
import {
  cilArrowBottom,
  cilListFilter,
  cilSortDescending,
} from "@coreui/icons";

function Sorting(props) {
  const { t } = useTranslation();
  const { items, setItems, active } = props;
  let options = [
    {
      label: t("sorting.without"),
      value: "null",
    },
  ];
  items.forEach((el) => {
    options.push({
      label: t(`sorting.${el.name}.plus`),
      value: `${el.name}`,
    });
    options.push({
      label: t(`sorting.${el.name}.minus`),
      value: `-${el.name}`,
    });
  });

  const handleChange = (val) => {
    if (val == "null") {
      setItems(null);
      return;
    }
    setItems(val);
  };
  return (
    <div className="sorting">
      {
        <>
          <div>
            <CFormLabel>{t("sorting.for")}</CFormLabel>

            <CFormSelect
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              value={active}
              defaulValue={active}
              options={options}
            />
          </div>
        </>
      }
    </div>
  );
}

export default Sorting;
