import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Heart from "../../assets/images/heart.svg";
import Cart from "../../assets/images/cartIcon.svg";
import FavoriteIconm from "../../assets/images/favorite.svg";
import ToggleIcon from "../../assets/images/toggle.svg";
import SearchIcon from "../../assets/images/search.svg";
import { formatBalance, renderCurrency, useLocalStorage } from "utils/utils";
import classNames from "classnames";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { CFormInput, CSpinner } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilDelete, cilHeart, cilSearch, cilX } from "@coreui/icons";
import {
  cnCountries,
  enCountries,
  esCountries,
  ruCountries,
} from "components/ChooseCountries/countries";
import ToastComponent from "components/ToastComponent";
import Preloader from "components/Preloader";
import { UserContext } from "core/contexts";
import Star from "assets/images/star.svg";
import StarActive from "assets/images/starActive.svg";
function ServiceItem(props) {
  const {
    el,
    favoriteServices,
    setFavoriteServices,
    getOrders,
    page,
    index,
    getFavorites,
    favorites,
    perPage,
    filters,
    updateUser,
    show,
    setShow,
    setShowAuthModal,
    authorized,
    setNeedRedirect,
  } = props;
  const [search, setSearch] = useState("");
  const [locale] = useLocalStorage("locale", "gb");

  const { t } = useTranslation();

  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState([]);
  const [topActive, setTopActive] = useState(false);
  const [preProducts, setPreProducts] = useState(null);
  const [defaultProducts, setDefaultProducts] = useState(null);
  const handleProduct = (id) => {
    setLoading(true);
    Axios.get(`${API_URL}/free-price/prices`, {
      params: {
        serviceUid: id,
      },
    })
      .then(function (response) {
        setLoading(false);
        if (response.data.data?.length > 0) {
          setProducts(response.data.data);
          setDefaultProducts(response.data.data);
          setPreProducts(response.data.data);
          let fullItems = document.querySelectorAll(".services__item");
          const list = document.querySelector(".services-list");
          list?.scroll(0, fullItems[index].offsetTop - 100);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleTop = (id) => {
    setLoading(true);
    Axios.get(`${API_URL}/order/stats`, {
      params: {
        service: id,
      },
    }).then(function (response) {
      setLoading(false);
      if (response.data.data?.length > 0) {
        setTopActive(true);
        setProducts(
          preProducts.filter(
            (p) =>
              response.data.data.filter((it) => it.country == p.productId)?.[0]
          )
        );
        setDefaultProducts(
          preProducts.filter(
            (p) =>
              response.data.data.filter((it) => it.country == p.productId)?.[0]
          )
        );
      }
    });
  };
  const handleChange = (val) => {
    setSearch(val);
  };
  useEffect(() => {
    if (search?.length > 0) {
      const countriesObj = {
        ru: ruCountries,
        gb: enCountries,
        cn: cnCountries,
        es: esCountries,
      };
      let list = countriesObj[locale];
      let data = Object.entries(list).filter((el) =>
        el[1].toLowerCase().includes(search.toLowerCase()?.trim())
      );
      let filteredList = defaultProducts.filter((el) => {
        let countryItems = data.filter((countryItem) => {
          if (el.countryId === countryItem[0]) {
            return countryItem[0];
          }
          return null;
        });
        if (countryItems?.length > 0) {
          return el;
        }
        return null;
      });

      setProducts(filteredList);
    } else {
      setProducts(defaultProducts);
    }
  }, [search]);

  return (
    <div
      className={classNames({
        services__item: true,
        show: show == el.service,
        disabled: loading,
      })}
      disabled={loading}
    >
      <div
        className="services__item-header"
        style={{ position: "relative" }}
        onClick={() => {
          if (show == el.service) {
            setShow(null);
          } else {
            setShow(el.service);
            handleProduct(el.service);
          }
          // if (!products) {
          // }
        }}
      >
        {loading && (
          <div className="with-preloader">
            <Preloader />
          </div>
        )}

        <div className="service__info">
          <div className={`bg bg-${el.service}`} alt={el.service} />
          <div className="service__name">{el.serviceName}</div>
          <div className="service__count">
            {el?.availablePhonesAmount == 0
              ? t("table.few")
              : `${el.availablePhonesAmount} ${t("services.itemsCount")}`}
          </div>
        </div>
        <div className="service__price">
          {t("services.from")} {formatBalance(el.priceAmount || 0)}
        </div>
        <img src={ToggleIcon} alt="" className="service__toggle" />
      </div>
      {products !== null && show == el.service && (
        <div className="service__products">
          <div className="service__filters">
            <div className="service__filters-item">
              <span className="service__filters-sort-by">{t("sort.by")}:</span>

              <span
                className={classNames({
                  active: topActive,
                  disabled: loading,
                })}
                disabled={loading}
                onClick={() => {
                  if (!topActive) {
                    handleTop(el.service);
                  } else {
                    setTopActive(false);
                    setProducts(preProducts);
                    setDefaultProducts(preProducts);
                  }
                }}
              >
                <div className="service__filters-sort-icon" />
                {t("CountriesTop")}
              </span>
            </div>
          </div>
          <div className="service__products-search">
            <img src={SearchIcon} className="services__search-icon" alt="" />

            <CFormInput
              placeholder={t("search.country")}
              value={search}
              onChange={(e) => handleChange(e.target.value)}
            />
            {search?.length > 1 ? (
              <CIcon icon={cilX} onClick={() => setSearch("")} />
            ) : (
              <></>
            )}
          </div>
          <div className="service__data">
            {products?.map((item) => {
              if (item.prices?.length == 0) {
                return;
              }
              return (
                <ServiceItemCountry
                  authorized={authorized}
                  setShowAuthModal={setShowAuthModal}
                  item={item}
                  favorites={favorites}
                  getFavorites={getFavorites}
                  setNeedRedirect={setNeedRedirect}
                  loadingId={loadingId}
                  setLoadingId={setLoadingId}
                  updateUser={updateUser}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default ServiceItem;

export const ServiceItemCountry = (props) => {
  const {
    item,
    authorized,
    setShowAuthModal,
    setNeedRedirect,
    setLoadingId,
    favorites,
    getFavorites,
    loadingId,
  } = props;
  const userContext = useContext(UserContext);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  const [topActive, setTopActive] = useState(false);
  function findMinMoneyAmount(data) {
    let res = data.reduce((min, item) => {
      const amount = parseFloat(item.moneyAmount); // Преобразуем строку в число
      return amount < min ? amount : min; // Сравниваем с текущим минимальным значением
    }, Infinity); // Начальное значение - бесконечность

    return formatBalance(res || 0);
  }

  function findIndexOfMinMoneyAmount(data) {
    let minIndex = -1;
    let minValue = Infinity;

    data.forEach((item, index) => {
      const amount = parseFloat(item.amount); // Преобразуем строку в число
      if (amount < minValue) {
        minValue = amount;
        minIndex = index;
      }
    });

    return minIndex;
  }
  let minItemIndex = findIndexOfMinMoneyAmount(item.prices);
  const buy = (id, price) => {
    if (!localStorage.getItem("token")) {
      setNeedRedirect(false);
      setShowAuthModal(true);
      return;
    }
    setLoadingId(
      loadingId.concat([
        {
          id: id,
          price: price,
        },
      ])
    );
    Axios.post(`${API_URL}/order`, {
      productId: id,
      maxPrice: price,
    })
      .then(function (response) {
        if (response.data.message == "No number") {
          ToastComponent("error", t("common.no_numbers"));
          setLoadingId(
            loadingId.filter((ids) => ids.id !== id && ids.price !== price)
          );
          userContext.updateUser();
          userContext.updateCounter();

          return;
        }
        if (response.data.status == "error") {
          ToastComponent("error", response.data.message);
        } else {
          ToastComponent("success", t("table.order_create"));
        }
        if (response.data.orderId) {
          userContext.updateUser();

          setLoadingId(
            loadingId.filter((ids) => ids.id !== id && ids.price !== price)
          );
        }
        userContext.updateCounter();
      })
      .catch((error) => {
        userContext.updateUser();
        setLoadingId(
          loadingId.filter((ids) => ids.id !== id && ids.price !== price)
        );
      });
  };

  const handleFavorite = (service, country, price, id) => {
    setLoadingId(loadingId.concat([service]));
    setLoading(true);
    setLoadingFavorite(true);
    if (
      favorites?.filter((fs) => fs.service == service && fs.country == country)
        ?.length == 0
    ) {
      Axios.post(`${API_URL}/favorite/product`, {
        serviceUid: service,
        countryId: country,
        defaultPrice: price,
      })
        .then(function (response) {
          getFavorites(() => {
            setLoadingFavorite(false);
            console.log(false, loadingFavorite);
          });
          setLoadingId(loadingId.filter((ids) => ids !== service));
          setLoading(false);
        })
        .catch((error) => {
          setLoadingId(loadingId.filter((ids) => ids !== service));
          setLoading(false);
          setLoadingFavorite(false);
        });
    } else {
      Axios.delete(`${API_URL}/favorite/product/${id}`)
        .then(function (response) {
          if (response) {
            getFavorites(() => {
              setLoadingFavorite(false);
              console.log(false, loadingFavorite);
            });
          }
        })
        .catch((error) => {
          setLoadingId(loadingId.filter((ids) => ids !== service));
          setLoadingFavorite(false);
        });
    }
  };
  return (
    <div
      className={classNames({
        "service__product-countries": true,
        // disabled: loadingId.includes(item.productId),
      })}
      // disabled={loadingId.includes(item.productId)}
    >
      <div className="service__product-country">
        <div className="service__country-header">
          <div className="service__info">
            <img
              src={
                favorites?.filter(
                  (fs) =>
                    fs.service == item.serviceUid &&
                    fs.country == item.countryId
                )?.length > 0
                  ? StarActive
                  : Star
              }
              disabled={loadingFavorite}
              onClick={(event) => {
                if (!loadingFavorite) {
                  handleFavorite(
                    item.serviceUid,
                    item.countryId,
                    item.defaultAmount,
                    item.id ||
                      favorites?.filter(
                        (fs) =>
                          fs.service == item.serviceUid &&
                          fs.country == item.countryId
                      )?.[0]?.id
                  );
                }
                event.stopPropagation();
              }}
              style={{ cursor: "pointer" }}
              className="service__star"
              alt=""
            />
            <div
              className={`flag flag-${item.countryId}`}
              alt={t(`country.${item.countryId}`)}
            />
            <div className="service__name">
              {t(`country.${item.countryId}`)}
            </div>
            <div className="service__count">
              {!item?.prices?.length > 0 ? (
                t("table.few")
              ) : (
                <>
                  {item.defaultCount ? (
                    <>
                      {item.defaultCount} {t("services.itemsCount")}.
                    </>
                  ) : (
                    <>{`${item.prices.reduce(
                      (total, item) => total + item.numbers,
                      0
                    )} ${t("services.itemsCount")}`}</>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="service__price">
            {t("services.from")} {formatBalance(item.defaultAmount || 0)}{" "}
            <button
              className="primary buy"
              onClick={() =>
                buy(item.productId, item.prices[minItemIndex]?.moneyAmount)
              }
              // disabled={loading || loadingId.includes(item.productId)}
              disabled={
                loadingId?.length > 0 ||
                loadingId?.some(
                  (ids) =>
                    ids.id == item.productId &&
                    ids.price == item.prices[minItemIndex]?.moneyAmount
                )
              }
            >
              {loadingId?.some(
                (ids) =>
                  ids.id == item.productId &&
                  ids.price == item.prices[minItemIndex]?.moneyAmount
              ) ? (
                <CSpinner size="sm" color="white" />
              ) : (
                <img src={Cart} alt="" />
                // <CSpinner size="sm" color="white" />
              )}
            </button>
          </div>
        </div>
        <div className="service__product-prices">
          <div className="service__product-show" onClick={() => setShow(!show)}>
            {t("common.choose_price")}{" "}
            <span
              className="manrope"
              style={{
                transform: `rotate(${show ? "-90deg" : "90deg"})`,
                display: "inline-block",
              }}
            >
              >
            </span>
          </div>
          <div
            className={classNames({
              "service__product-prices-items": true,
              active: show,
            })}
          >
            {item.prices.map((el) => {
              return (
                <div
                  className="service__product-prices-item"
                  key={`${el.amount}_${el.numbers}_${item.countryId}`}
                >
                  <div className="service__prices-item-left">
                    <div className={`bg bg-${item.serviceUid}`} />
                    <span className="manrope">></span>
                    <div className={`flag flag-${item.countryId}`} />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="service__prices-item-amount">
                        {formatBalance(el.amount || 0)}{" "}
                      </div>
                      <div className="service__prices-item-count">
                        {el.numbers} {t("services.itemsCount")}.
                      </div>
                    </div>
                  </div>
                  <button
                    className="primary buy"
                    onClick={() => buy(item.productId, el.amount)}
                    disabled={
                      loadingId?.length > 0 ||
                      loadingId?.some(
                        (ids) =>
                          ids.id == item.productId && ids.price == el.amount
                      )
                    }
                  >
                    {loadingId?.some(
                      (ids) =>
                        ids.id == item.productId && ids.price == el.amount
                    ) ? (
                      <CSpinner size="sm" color="white" />
                    ) : (
                      <img src={Cart} alt="" />
                    )}
                    {t("table.buy")}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

function ServiceItemLight(props) {
  const {
    el,
    favoriteServices,
    setFavoriteServices,
    getOrders,
    page,
    index,
    getFavorites,
    favorites,
    perPage,
    filters,
    updateUser,
    show,
    setShow,
    setShowAuthModal,
    authorized,
    setNeedRedirect,
  } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState([]);
  return (
    <>
      <div
        className={classNames({
          services__item: true,
          show: true,
          light: true,
          disabled: loading,
        })}
        disabled={loading}
      >
        <div className="services__item-header" style={{ position: "relative" }}>
          {loading && (
            <div className="with-preloader">
              <Preloader />
            </div>
          )}

          <div className="service__info">
            <div className={`bg bg-${el.service}`} alt={el.service} />
            <div className="service__name">{el.serviceName}</div>
            <div className="service__count">
              {el?.availablePhonesAmount
                ? `${el.availablePhonesAmount} ${t("services.itemsCount")}.`
                : `${el.prices.reduce(
                    (total, item) => total + item.numbers,
                    0
                  )} ${t("services.itemsCount")}`}
              .
            </div>
          </div>
          <div className="service__price">
            {t("services.from")} {formatBalance(el.defaultPrice || 0)}
          </div>
        </div>
        <div className="service__products">
          <div className="service__data">
            <ServiceItemCountry
              authorized={authorized}
              setShowAuthModal={setShowAuthModal}
              item={{
                prices: el.prices,
                countryId: el.country,
                service: el.service,
                serviceUid: el.service,
                defaultAmount: el.defaultPrice,
                defaultCount: el.defaultAmount,
                id: el.id,
                productId: el.productId,
              }}
              favorites={favorites}
              getFavorites={getFavorites}
              setNeedRedirect={setNeedRedirect}
              loadingId={loadingId}
              setLoadingId={setLoadingId}
              updateUser={updateUser}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export { ServiceItemLight };
