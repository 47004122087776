import React from "react";
import "./index.scss";
import { CFormSelect, CPagination } from "@coreui/react";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

function TablePagination(props) {
  const { t } = useTranslation();
  const {
    total,
    perPage,
    page,
    needAll,
    setPage,
    setPerPage,
    showAll,
    withoutPerPage = false,
  } = props;
  let options = [
    { label: `${t("table.per_page")}: 5`, value: 5 },
    { label: `${t("table.per_page")}: 10`, value: 10 },
    { label: `${t("table.per_page")}: 20`, value: 20 },
    { label: `${t("table.per_page")}: 25`, value: 25 },
    { label: `${t("table.per_page")}: 50`, value: 50 },
    { label: `${t("table.per_page")}: 100`, value: 100 },
  ];
  if (needAll) {
    options.push({ label: `${t("table.per_page_all")}`, value: total });
  }
  return (
    <div
      className={classNames({
        "table-pagination": true,
        [`show-all`]: !!showAll,
      })}
    >
      <div>
        <p className="table-pagination__text">
          <div>
            {t("notes")} {perPage * page - perPage + 1} {t("to")}{" "}
            {perPage * page > total ? total : perPage * page} {t("of")} {total}
          </div>
          {showAll && (
            <>
              {perPage != total ? (
                <span
                  className="table-pagination__show-all link link-info"
                  onClick={() => setPerPage(total)}
                >
                  {t("pagination.show_all")}
                </span>
              ) : (
                <span
                  className="table-pagination__show-all link link-info"
                  onClick={() => setPerPage(10)}
                >
                  {t("pagination.hide_all")}
                </span>
              )}
            </>
          )}
        </p>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={(page) => setPage(page.selected + 1)}
          pageRangeDisplayed={2}
          pageCount={Math.ceil(total / perPage)}
          previousLabel="<"
          renderOnZeroPageCount={null}
          forcePage={page - 1}
        />
        {!withoutPerPage && (
          <CFormSelect
            onChange={(e) => {
              setPerPage(e.target.value);
            }}
            value={perPage}
            options={options}
          />
        )}
      </div>
    </div>
  );
}

export default TablePagination;
