import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/logo-sms-white.svg";
import Plus1 from "../../assets/images/about-plus-1.svg";
import Plus2 from "../../assets/images/about-plus-2.svg";
import Plus3 from "../../assets/images/about-plus-3.svg";
import Li from "../../assets/images/about__li.svg";
function About() {
  const { t } = useTranslation();
  const items = [
    {
      title: t("about.plus1.title"),
      subtitle: t("about.plus1.subtitle"),
      icon: Plus1,
    },
    {
      title: t("about.plus2.title"),
      subtitle: t("about.plus2.subtitle"),
      icon: Plus2,
    },
    {
      title: t("about.plus3.title"),
      subtitle: t("about.plus3.subtitle"),
      icon: Plus3,
    },
  ];
  return (
    <section className="about light-grey-block" id="about" >
      <div className="about__content">
        <div>
          <h1>{t("heading.tagline")}</h1>
          <h4>{t("heading.subtitle")}</h4>
        </div>
        <div className="about__items">
          {items.map((el) => {
            return (
              <div className="about__item">
                <div className="about__item-image">
                  <img src={el.icon} alt="" />
                </div>
                <div className="about__item-title">{el.title}</div>
                <div className="about__item-subtitle">{el.subtitle}</div>
              </div>
            );
          })}
        </div>
        <div className="about__info">
          <img src={Logo} className="about__logo" alt="" />
          <h5>{t("about.subtitle")}</h5>
          <ul>
            <li>
              <img src={Li} alt="" />
              {t("about.list1")}
            </li>
            <li>
              <img src={Li} alt="" />
              {t("about.list2")}
            </li>
            <li>
              <img src={Li} alt="" />
              {t("about.list3")}
            </li>
          </ul>
        </div>
        <div className="about__subtext">{t("about.subtext")}</div>
      </div>
    </section>
  );
}

export default About;
