import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import RulesBlock from "components/RulesBlock";
import Footer from "components/Footer";

function RulesPage(props) {
  const { t } = useTranslation();

  return (
    <>
      <div className="about light-grey-block">
        <RulesBlock />
      </div>
    </>
  );
}

export default RulesPage;
