import React, { useState, useEffect, useContext } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cilClock,
  cilX,
  cibVk,
  cilCheck,
  cilOptions,
  cilListFilter,
} from "@coreui/icons";
import { CSpinner, CTooltip } from "@coreui/react";

import TablePagination from "components/TablePagination";
import Filters from "components/Filters";
import { formatBalance, useWindowSize } from "utils/utils";
import Sorting from "components/Sorting";
import Preloader from "components/Preloader";
import { UserContext } from "core/contexts";

function PaymentTable(props) {
  const {
    currency,
    payments,
    page,
    setPage,
    total,
    perPage,
    setPerPage,
    filters,
    setFilters,
    isLoading,
    getPayments,
    sorting,
    activeSorting,
    setSorting,
  } = props;
  const userContext = useContext(UserContext);

  const [count, setCount] = useState(0);
  const windowSize = useWindowSize()[0];
  const [showFilters, setShowFilters] = useState(false);
  const isMobile = windowSize < 1600;

  const renderStatus = (status) => {
    return t(`table.${status}`);
  };
  const renderIcon = (iconName, country) => {
    const icons = {
      vk: cibVk,
    };
    if (!icons[iconName]) {
      return `${iconName} - ${country}`;
    } else {
      return (
        <>
          <CIcon icon={icons[iconName]} /> - {country}
        </>
      );
    }
  };
  const { t } = useTranslation();
  const columns = [
    {
      id: "id",
      name: t("common.id"),

      selector: (row) => <b>{row.id}</b> || "—",
      // sortable: true,
      notMobile: true,
      width: "60px",
    },
    {
      id: "serviceUid",
      name: t("table.type"),

      selector: (row) => t(`table.${row.type}`) || "—",
      // sortable: true,
      // compact: true,
      allowOverflow: true,
    },
    {
      id: "provider",
      name: t("table.provider"),

      selector: (row) => t(`${row.paymentProvider}`) || "—",
      mobileHeader: true,
      compact: true,
    },
    {
      id: "createDate",
      name: t("table.date"),

      selector: (row) => new Date(row.createdAt).toLocaleString() || "—",
      // sortable: true,
    },

    {
      id: "status",
      name: t("table.status"),

      selector: (row) => renderStatus(row.status),
      // sortable: true,
    },
    {
      id: "amount",
      name: t("table.amount"),

      selector: (row) => formatBalance(row.amount || 0),
      // sortable: true,
      mobileFooter: true,
    },
  ];

  useEffect(() => {
    getPayments(page, perPage, filters);
  }, []);
  useEffect(() => {
    if (userContext.eventsCounter > 0) {
      getPayments(page, perPage, filters);
    }
  }, [userContext.eventsCounter]);
  useEffect(() => {
    count > 1 && getPayments(page, perPage, filters);
  }, [count]);

  const renderMobileItem = (el, row) => {
    return (
      <div className="mobile-list__line">
        <div className="mobile-list__key">{el.name}</div>
        <div className="mobile-list__value">{el.selector(row)}</div>
      </div>
    );
  };
  return (
    <>
      <div className="grey-block">
        {isLoading && (
          <div className="with-preloader">
            <Preloader />
          </div>
        )}
        <div className="orders-table">
          <div className="filters-wrapper__items">
            {filters?.length > 0 && (
              <Filters
                items={filters}
                setFilters={(val) => {
                  setFilters(val);
                  setCount(count + 1);
                  if (page == 1 && count > 0) {
                    // getPayments(page, perPage, filters);
                  } else if (page !== 1 && count > 0) {
                    setPage(1);
                  }
                }}
              />
            )}
            {sorting?.length > 0 && (
              <>
                <Sorting
                  items={sorting}
                  setItems={setSorting}
                  active={activeSorting}
                />
              </>
            )}
          </div>
          {!isMobile ? (
            <DataTable
              columns={columns}
              data={payments}
              sortIcon={<CIcon icon={cilArrowTop} />}
              // progressPending={isLoading}
              progressComponent={<CSpinner color="primary" />}
              noDataComponent={
                <div className="no-data">{t("table.notFound")}</div>
              }
            />
          ) : (
            <div className="mobile-list">
              {payments?.length > 0 ? (
                <>
                  {payments.map((row) => {
                    return (
                      <div className="mobile-list__item">
                        {columns.map((el) => {
                          if (el.mobileHeader) {
                            return (
                              <div className="mobile-list__header">
                                {renderMobileItem(el, row)}
                              </div>
                            );
                          }
                        })}
                        <div className="mobile-list__body">
                          {columns.map((el) => {
                            if (!el.mobileFooter && !el.mobileHeader) {
                              return renderMobileItem(el, row);
                            }
                          })}
                        </div>
                        {columns.map((el) => {
                          if (el.mobileFooter) {
                            return (
                              <div className="mobile-list__footer">
                                {renderMobileItem(el, row)}
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="no-data">{t("table.notFound")}</div>
              )}
            </div>
          )}
        </div>
      </div>
      {payments?.length > 0 && (
        <TablePagination
          page={page}
          setPage={(page) => {
            setPage(page);
            getPayments(page, perPage, filters);
          }}
          total={total}
          withoutPerPage={true}
          perPage={perPage}
          setPerPage={(val) => {
            setPerPage(val);
            setPage(1);
            getPayments(1, val, filters);
          }}
        />
      )}
    </>
  );
}

export default PaymentTable;
