import React, { useContext, useState } from "react";
import {
  CButton,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CInputGroupText,
} from "@coreui/react";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { useTranslation } from "react-i18next";
import { renderCurrency } from "utils/utils";
import ToastComponent from "components/ToastComponent";
import { UserContext } from "core/contexts";
import ModalCloseIcon from "../../assets/images/modal-close.svg";

function BalanceModal(props) {
  const { show, setShow, id, value, callback } = props;
  const { t } = useTranslation();
  const [val, setVal] = useState(value);
  const user = useContext(UserContext).user;
  const currency = user?.currency;
  const submit = (e) => {
    e.preventDefault();
    callback(val);
    setShow(false);
    setVal(null);
  };
  return (
    <CModal visible={show} onClose={() => setShow(false)}>
      <CModalHeader>
        <CModalTitle>{t("heading.balance_correction")}</CModalTitle>
        <div className="modal-close" onClick={() => setShow(false)}>
          <img src={ModalCloseIcon} alt="" />
        </div>
      </CModalHeader>
      {currency && (
        <form onSubmit={(e) => submit(e)}>
          <CModalBody>
            {currency && (
              <div className="balance-block">
                {t("common.balance")}:{" "}
                <span className={`${!!val && val != 0 ? "borderline" : ""}`}>
                  {parseFloat(value)?.toFixed(2)}
                  {renderCurrency(currency || "USD")}
                </span>
                {!!val && val != 0 && (
                  <span
                    className={`${val > 0 ? "text-success" : "text-danger"}`}
                  >
                    &nbsp;{(parseFloat(value) + parseFloat(val))?.toFixed(2)}
                    {renderCurrency(currency || "USD")}
                  </span>
                )}
              </div>
            )}
            <div>
              <br />
              <CInputGroup>
                <CFormInput
                  type="number"
                  placeholder={t("table.amount")}
                  value={val}
                  step="any"
                  required
                  defaultValue={val}
                  onChange={(e) => {
                    if (e.target.value.split(".")?.[1]?.length > 2) {
                      return;
                    }
                    if (e.target.value > 999999.99) {
                      return setVal(999999.99);
                    }
                    if (e.target.value < -999999.99) {
                      return setVal(-999999.99);
                    }
                    setVal(e.target.value);
                  }}
                />
                <CInputGroupText>{renderCurrency(currency)}</CInputGroupText>
              </CInputGroup>
            </div>
          </CModalBody>
          <CModalFooter>
            <button
              className="primary"
              type="submit"
              disabled={!val || val == 0}
            >
              {t("common.submit")}
            </button>
          </CModalFooter>
        </form>
      )}
    </CModal>
  );
}
export default BalanceModal;
