import UsersIcon from "assets/images/user.svg";
import PartnersIcon from "assets/images/partners.svg";
import StatsIcon from "assets/images/stats.svg";
import OrdersIcon from "assets/images/order.svg";
import PaymentsIcon from "assets/images/payments.svg";
import CouponsIcon from "assets/images/coupons.svg";
import SettingsIcon from "assets/images/settings.svg";
import LogsIcon from "assets/images/logs.svg";
import RulesIcon from "assets/images/rules.svg";
import GoShopIcon from "assets/images/goshop.svg";

export const icons = {
  "heading.users": UsersIcon,
  "heading.partners": PartnersIcon,
  "menu.stats": StatsIcon,
  "heading.orders": OrdersIcon,
  "heading.payments": PaymentsIcon,
  "menu.coupons": CouponsIcon,
  "menu.admin_settings": SettingsIcon,
  "menu.logs": LogsIcon,
  "nav.rules": RulesIcon,
  // "menu.partner": cilUser,
  // "menu.partners": cilPeople,
  // "menu.devices": cilDevices,
  // "menu.simcards": cilSim,
  // "menu.payments": cilCreditCard,
  // "menu.services": cilSettings,
  // "menu.transactions": cilTransfer,
  // "menu.operators": cilVoiceOverRecord,
  // "menu.profiles": cilUser,
  // "menu.simfilters": cilChatBubble,
  // "menu.orders": cilListRich,
  // "menu.simbanks": cibServerFault,
  // "menu.iccid_bindings": cilVector,
};
