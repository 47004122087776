import React, { useState, useEffect, useContext } from "react";
import "./index.scss";
import Sidebar from "components/Sidebar";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { useTranslation } from "react-i18next";
import { UserContext } from "core/contexts";
import OrderTable from "pages/AdminMainPage/OrderTable";
import { CFormSelect } from "@coreui/react";
import UsersTable from "./usersTable";
import { useLocalStorage } from "utils/utils";
import AdminSidebar from "components/AdminSidebar";

function AdminUsersPage(props) {
  const { t } = useTranslation();
  const [orderStats, setOrderStats] = useState([]);
  const [userStats, setUserStats] = useState({});
  const [users, setUsers] = useState([]);
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [activeSorting, setActiveSorting] = useState(null);
  const [expanded, setExpanded] = useLocalStorage("expanded", "true");
  const [userSorting, setUserSorting] = useState([
    {
      name: "date",
      label: t("sorting.date"),
      value: null,
    },
  ]);
  const [filters, setFilters] = useState([
    {
      name: "email",
      type: "string",
      value: null,
    },
  ]);
  const user = userContext.user;

  const getUsers = (pageVal, perPageVal, filtersVal, activeSortingVal) => {
    setLoading(true);
    let params = {
      page: pageVal,
      perPage: perPageVal,
    };
    if (activeSortingVal !== "sorting.null" && activeSortingVal !== null) {
      params["sort"] = [activeSortingVal];
    }
    filtersVal?.forEach((el) => {
      if (el.value && el.value !== "all") {
        if (el.type == "date") {
          let date = new Date(el.value);
          // date.setDate(date.getDate() + 1);
          params = {
            ...params,
            [el.name]: date.toISOString(),
          };

          return;
        }
        params = { ...params, [el.name]: el.value };
      }
    });
    Axios.get(`${API_URL}/admin/user/all`, {
      params: params,
    })
      .then(function (response) {
        setLoading(false);
        if (response.data?.data?.length > 0) {
          setUsers(response.data.data);
          setTotal(response.data.total);
        } else {
          setUsers([]);
          setTotal(0);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    Axios.get(`${API_URL}/admin/user/aggregation`)
      .then(function (response) {
        if (response.data) {
          setUserStats(response.data);
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      {" "}
      <div className="admin-panel__item">
        <div className="stats">
          <h6>{t("heading.users")}</h6>
          <div className="stats-list">
            <div className="stats-item">
              <span>{t("stats.users")}</span>:{" "}
              <span>{userStats?.usersCount}</span>
            </div>
            <div className="stats-item">
              <span>{t("stats.admins")}</span>:{" "}
              <span>{userStats?.adminsCount}</span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="admin-panel__item">
        <UsersTable
          users={users}
          setUsers={setUsers}
          getUsers={getUsers}
          total={total}
          setTotal={setTotal}
          sorting={userSorting}
          filters={filters}
          setFilters={setFilters}
          activeSorting={activeSorting}
          setSorting={setActiveSorting}
          page={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          isLoading={loading}
        />
      </div>
      {/* <div className="container container-panel">
          <div className="panel__content">
            <div className="balance stats ">
              <div className="shadow-inset">
                <div className="stats-list">
                  <h6>{t("heading.users")}</h6>
                  <div className="stats-item">
                    <span>{t("stats.users")}</span>
                    <b>{userStats?.usersCount}</b>
                  </div>
                  <div className="stats-item">
                    <span>{t("stats.admins")}</span>{" "}
                    <b>{userStats?.adminsCount}</b>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="stats-tables user-stats">
              <UsersTable
                users={users}
                setUsers={setUsers}
                getUsers={getUsers}
                total={total}
                setTotal={setTotal}
                sorting={userSorting}
                filters={filters}
                setFilters={setFilters}
                activeSorting={activeSorting}
                setSorting={setActiveSorting}
                page={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerPage}
                isLoading={loading}
              />
            </div>
            <br />
          </div>
        </div> */}
    </>
  );
}
export default AdminUsersPage;
