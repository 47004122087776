import React, { useContext } from "react";
import {
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import { formatBalance, getDate, renderCurrency } from "utils/utils";
import CIcon from "@coreui/icons-react";
import { cilSnowflake, cilPen } from "@coreui/icons";
import EditIcon from "assets/images/editPencil.svg";
export const renderInputs = (
  value,
  t,
  readOnly,
  user,
  data,
  setValue,
  addData = null
) => {
  const label = renderLabel(value, t, user);
  const inputId = `form_${value.name}`;
  const placeholder = t(`placeholder.${value.name}`);
  const inputValue = renderValue(value);
  const isBalance = value.name === "balance";
  return (
    <div>
      <CFormLabel>{label}</CFormLabel>
      <CInputGroup>
        <CFormInput
          type={"text"}
          id={inputId}
          placeholder={placeholder}
          value={isBalance ? formatBalance(inputValue) : inputValue}
          // onChange={(e) =>
          //   setValue(
          //     data.map((el) =>
          //       el.name == value.name
          //         ? {
          //             name: el.name,
          //             value: e.target.value.toString(),
          //             type: el.type,
          //           }
          //         : el
          //     )
          //   )
          // }
          defaultValue={inputValue}
          disabled={readOnly}
        />
        {isBalance && (
          <>
            {/* <CInputGroupText>{renderCurrency(user.currency)}</CInputGroupText> */}
            {setValue && !window.location.href.includes("panel/settings") && (
              <CInputGroupText onClick={() => addData.setShow(true)}>
                <img src={EditIcon} alt="" />
              </CInputGroupText>
            )}
          </>
        )}
      </CInputGroup>
    </div>
  );
};

export const renderValue = (value) => {
  if (!value?.value) {
    return;
  }
  if (value.type === "datetime") {
    const date = getDate(value.value);
    return `${date}, ${value?.value?.split("T")[1].split("+")[0]}`;
  }
  return value.value;
};

function renderLabel(value, translator, user) {
  if (value.name === "balance" && user?.frozenBalance) {
    return (
      <>
        {translator(`placeholder.${value.name}`)}{" "}(
        <span
          style={{
            color: "#8161EB",
            transform: "translateY(4px)",
            display: "inline-block",
          }}
        >
          &nbsp;*&nbsp;
        </span>
        {formatBalance(user.frozenBalance)})
      </>
    );
  }

  return <>{translator(`placeholder.${value.name}`)}</>;
}
