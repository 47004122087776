import React, { useState, useEffect, useContext } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cibVk,
  cilPhone,
  cilPen,
  cilX,
  cilCheckAlt,
  cibTelegramPlane,
  cibWhatsapp,
  cibFacebookF,
  cilXCircle,
  cibInstagram,
  cilChevronTop,
  cilInfo,
  cilChevronCircleRightAlt,
  cilOptions,
  cilListFilter,
  cilLoopCircular,
  cilReload,
} from "@coreui/icons";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { formatBalance, getDate, useLocalStorage } from "utils/utils";
import { CSpinner } from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { CTooltip, CButton } from "@coreui/react";
import TablePagination from "components/TablePagination";
import Filters from "components/Filters";
import Sorting from "components/Sorting";
import { useWindowSize } from "utils/utils";
import StatusWaitingIcon from "../../assets/images/status_loading.svg";
import StatusTextIcon from "../../assets/images/status_text.svg";
import TimeIcon from "../../assets/images/orderTime.svg";
import AmountIcon from "../../assets/images/orderAmount.svg";
import Copy from "../../assets/images/copy.svg";
import StatusDeclinedIcon from "../../assets/images/closeNew.svg";

import {
  cnCountries,
  enCountries,
  esCountries,
  ruCountries,
} from "components/ChooseCountries/countries";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { confirmAlert } from "react-confirm-alert";
import { useTimer } from "react-timer-hook";
import Preloader from "components/Preloader";
import { UserContext } from "core/contexts";

function OrdersList(props) {
  const {
    getOrders,
    orders,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    isLoading,
    filters,
    title,
    sorting,
    activeSorting,
    setSorting,
    setFilters,
    setIsLoading,
    currency,
    updateUser,
    needCancel,
    needUser = false,
    needResend = false,
  } = props;

  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const windowSize = useWindowSize()[0];
  const userContext = useContext(UserContext);
  const [count, setCount] = useState(0);
  const isMobile = windowSize < 991;
  const [locale] = useLocalStorage("locale", "gb");
  const countriesObj = {
    ru: ruCountries,
    gb: enCountries,
    cn: cnCountries,
    es: esCountries,
  };
  const actualLanguage = countriesObj[locale];

  useEffect(() => {
    getOrders(page, perPage, filters, false);
  }, [page, activeSorting]);
  useEffect(() => {
    setPage(1);
  }, [perPage, filters]);
  useEffect(() => {
    if (
      orders.filter(
        (el) => el.status == "waiting_phone" || el.status == "waiting_sms"
      )?.length > 0
    ) {
      const interval = setInterval(() => {
        getOrders(page, perPage, filters, false);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, []);

  // const renderStatus = (status) => {
  //   const statusIcons = {
  //     waiting_phone: cilPhone,
  //     waiting_sms: cilPen,
  //     canceled: cilX,
  //     finished: cilCheckAlt,
  //   };

  //   if (statusIcons[status]) {
  //     return (
  //       <CTooltip content={<>{t(`table.${status}`)}</>}>
  //         <CIcon icon={statusIcons[status]} />
  //       </CTooltip>
  //     );
  //   } else {
  //     return status;
  //   }
  // };
  // const renderSms = (smsContent) => {
  //   if (smsContent) {
  //     return (
  //       <CTooltip content={<>{smsContent}</>}>
  //         <div className="sms-content-wrapper">
  //           <div className="sms-content">{smsContent} </div>
  //           <span className="question">?</span>
  //         </div>
  //       </CTooltip>
  //     );
  //   }
  //   return "—";
  // };
  const getOrderPrice = (oId) => {
    setLoadingId(oId);
    setIsLoading(true);
    Axios.get(`${API_URL}/order/price`, {
      params: {
        orderId: oId,
      },
    })
      .then(function (response) {
        if (response.data.canRepeatSms) {
          setLoadingId("");
          setIsLoading(false);
          if (parseFloat(response.data.amount) > 0) {
            repeatAmountInfo(oId, response.data.amount, response.data.currency);
          } else {
            orderRepeat(oId, response.data.amount, response.data.currency);
          }
        } else {
          ToastComponent("error", t("order.cantRepeatSms"));
        }
      })
      .catch((error) => {
        setLoadingId("");
        setIsLoading(false);
      });
  };
  const orderRepeat = (oId, repeatAmount, repeatCurrency) => {
    setLoadingId(oId);
    setIsLoading(true);
    Axios.post(`${API_URL}/order/repeat`, {
      orderId: oId,
      amount: repeatAmount,
      currency: repeatCurrency,
    })
      .then(function (response) {
        ToastComponent(
          response?.data?.status,
          t(`order.repeat_${response.data.status}`)
        );
        updateUser();
        setIsLoading(false);
        setLoadingId("");
        // }
      })
      .catch((error) => {
        setIsLoading(false);
        setLoadingId("");
      });
  };
  const repeatAmountInfo = (oId, repeatAmount, repeatCurrency) => {
    confirmAlert({
      title: `${t("order.repeatOrderTitle")}${formatBalance(
        repeatAmount || 0
      )}`,
      message: t("order.repeatOrderSubtitle"),
      buttons: [
        {
          label: t("common.yes"),
          onClick: () => orderRepeat(oId, repeatAmount, repeatCurrency),
        },
        {
          label: t("common.no"),
          onClick: () => console.log("no"),
        },
      ],
    });
  };

  const renderDate = (date) => {
    const newDate = new Date(date).toLocaleString();
    return (
      <div>
        <CTooltip content={<>{`${newDate}`}</>}>
          <div>{newDate?.split(",")[0] || "—"}</div>
        </CTooltip>
      </div>
    );
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="orders-list">
        {isLoading && (
          <div className="with-preloader">
            <Preloader />
          </div>
        )}

        {orders.length > 0 ? (
          <>
            {orders.map((row) => {
              return (
                <OrdersListItem
                  row={row}
                  setLoading={setLoading}
                  setLoadingId={setLoadingId}
                  userContext={userContext}
                  key={row.id}
                />
              );
            })}
          </>
        ) : (
          <>
            <div className="no-data">{t("table.notFound")}</div>
          </>
        )}

        {orders?.length > 0 && (
          <>
            <TablePagination
              page={page}
              setPage={setPage}
              total={total}
              perPage={perPage}
              withoutPerPage
              setPerPage={(val) => {
                setPerPage(val);
                if (page == 1) {
                  getOrders(page, val, filters, false);
                }
              }}
            />
          </>
        )}
      </div>
    </>
  );
}

export default OrdersList;

const Timer = ({ createdAt }) => {
  const [timeLeft, setTimeLeft] = useState("20:00");

  useEffect(() => {
    // Преобразуем строку в объект Date и добавляем 20 минут
    const initialDate = new Date(createdAt);
    const targetTime = new Date(initialDate.getTime() + 20 * 60 * 1000);

    const updateTimer = () => {
      const now = new Date().getTime();
      const difference = targetTime - now;

      if (difference <= 0) {
        setTimeLeft("00:00");
        return;
      }

      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      // Форматируем с ведущими нулями
      const formatted = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;

      setTimeLeft(formatted);
    };

    // Сразу обновляем таймер
    updateTimer();

    // Запускаем обновление каждую секунду
    const interval = setInterval(updateTimer, 1000);

    // Очистка интервала при размонтировании
    return () => clearInterval(interval);
  }, [createdAt]);

  return timeLeft;
};

export const OrdersListItem = ({
  row,
  userContext,
  setLoadingId,
  setLoading,
}) => {
  // Добавляем состояние для отслеживания времени
  const [timeLeft, setTimeLeft] = useState("20:00");

  const cancelOrder = (id) => {
    setLoading(true);
    setLoadingId(id);
    Axios.put(`${API_URL}/order`, {
      orderId: id,
    })
      .then(function (response) {
        if (response.data.orderId) {
          ToastComponent("success", t("table.order_cancel"));
          userContext.updateCounter();
          setLoading(false);
          userContext.updateUser();
          setLoadingId("");
        }
      })
      .catch((error) => {
        setLoading(false);
        userContext.updateUser();
        setLoadingId("");
      });
  };
  const [locale] = useLocalStorage("locale", "gb");
  const countriesObj = {
    ru: ruCountries,
    gb: enCountries,
    cn: cnCountries,
    es: esCountries,
  };

  const actualLanguage = countriesObj[locale];
  const { t } = useTranslation();
  const renderIcon = (iconName, country, serviceName) => {
    return (
      <div className="d-flex align-items-center">
        <CTooltip content={<>{serviceName}</>}>
          <div className={`bg bg-${iconName}`} alt={iconName} />
        </CTooltip>
        <span className="manrope">></span>
        <CTooltip content={<>{actualLanguage[country]}</>}>
          <div className={`flag flag-${country}`} />
        </CTooltip>
      </div>
    );
  };
  // Логика таймера, перенесенная из компонента Timer
  useEffect(() => {
    const initialDate = new Date(row.createdAt);
    const targetTime = new Date(initialDate.getTime() + 20 * 60 * 1000);

    const updateTimer = () => {
      const now = new Date().getTime();
      const difference = targetTime - now;

      if (difference <= 0) {
        setTimeLeft("00:00");
        return;
      }

      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      const formatted = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;

      setTimeLeft(formatted);
    };

    updateTimer();
    const interval = setInterval(updateTimer, 1000);
    return () => clearInterval(interval);
  }, [row.createdAt]);

  // Удаляем вызовы Timer() и используем timeLeft
  const renderStatus = (status) => {
    if (timeLeft === "00:00" && row.status !== "finished") {
      return (
        <>
          <img src={StatusDeclinedIcon} alt="" /> {t(`table.canceled`)}
        </>
      );
    } else if (status == "waiting_sms" || status == "waiting_phone") {
      return (
        <>
          <img src={StatusWaitingIcon} alt="" /> {t(`table.${status}`)}
        </>
      );
    } else if (
      status == "canceled" ||
      status == "declined" ||
      status == "no_numbers" ||
      status == "failed"
    ) {
      return (
        <>
          <img src={StatusDeclinedIcon} alt="" /> {t(`table.${status}`)}
        </>
      );
    } else if (row.smsContent && status == "finished") {
      return (
        <>
          <img src={StatusTextIcon} alt="" />{" "}
          <span>
            {t(`status.order_complete`)}: <b>{row.smsContent}</b>
          </span>
        </>
      );
    }
  };

  return (
    <div className={"orders__item"}>
      <div className="orders__country">
        {renderIcon(row.serviceUid, row.countryAlpha2, row.serviceName)}
        <div className="orders__country-name">
          {`${row.serviceName}(${actualLanguage[row.countryAlpha2]})`}
        </div>
      </div>
      <div
        className={`orders__phone ${!row.phone ? "orders__phone_empty" : ""}`}
      >
        {row.phone ? `+${row.phone}` : ""}
        {row.phone && (
          <button
            className="primary"
            onClick={() => {
              navigator.clipboard.writeText(row.phone);
              ToastComponent("success", t("copy.phone"));
            }}
          >
            <img src={Copy} alt="" />
          </button>
        )}
      </div>
      <div className="orders__amount">
        <div>
          <img src={AmountIcon} alt="" />
          {formatBalance(row.amount || 0)}
        </div>
      </div>
      <div className="orders__time">
        {row.status == "waiting_sms" && (
          <div>
            <img src={TimeIcon} alt="" />
            <span>{timeLeft}</span> {/* Используем состояние вместо Timer */}
          </div>
        )}
      </div>

      <div className="orders__action">
        {row.status == "waiting_sms" && timeLeft !== "00:00" && (
          <button className="error" onClick={() => cancelOrder(row.id)}>
            {t("confirm.cancel")}
          </button>
        )}
      </div>
      <div className="orders__status">{renderStatus(row.status)}</div>
    </div>
  );
};
