import React, { useContext, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar";

import { UserContext } from "core/contexts";
import RulesBlock from "components/RulesBlock";
import Notifier from "../../components/Notifier";
import Layout from "components/Layout";

function PanelRulesPage(props) {
  const user = useContext(UserContext).user;
  const { t } = useTranslation();

  return (
    <>
      <RulesBlock />
    </>
  );
}

export default PanelRulesPage;
