import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cibVk,
  cilPhone,
  cilPen,
  cilX,
  cilCheckAlt,
  cibTelegramPlane,
  cibWhatsapp,
  cibFacebookF,
  cilXCircle,
  cibInstagram,
  cilChevronTop,
  cilCheck,
  cilListFilter,
  cilOptions,
  cilSettings,
} from "@coreui/icons";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { API_URL } from "utils/settings";
import { formatBalance, getDate } from "utils/utils";
import { CSpinner } from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { CTooltip, CButton } from "@coreui/react";
import TablePagination from "components/TablePagination";
import Filters from "components/Filters";
import Sorting from "components/Sorting";
import { useWindowSize } from "utils/utils";
import Preloader from "components/Preloader";
import CheckIcon from "../../assets/images/userCheck.svg";
import CloseIcon from "../../assets/images/userUnCheck.svg";
function UsersTable(props) {
  let history = useHistory();

  const {
    getUsers,
    users,
    total,
    page,
    setTotal,
    perPage,
    filters,
    setFilters,
    title,
    sorting,
    activeSorting,
    setSorting,
    setPage,
    setPerPage,
    isLoading,
    partner,
  } = props;
  const [count, setCount] = useState(0);

  const [showFilters, setShowFilters] = useState(false);
  const windowSize = useWindowSize()[0];
  const isMobile = windowSize < 991;
  useEffect(() => {
    getUsers(page, perPage, filters, activeSorting);
  }, [page, activeSorting]);
  useEffect(() => {
    setPage(1);
  }, [perPage]);
  const { t } = useTranslation();
  const columns = [
    {
      id: "id",
      name: t("common.email"),
      grow: 2,
      selector: (row) => row.email || "—",
      sortable: false,
    },
    {
      id: "emailConfirmed",
      name: t("table.email_confirmed"),
      
      selector: (row) =>
        row.emailConfirmed ? (
          <img src={CheckIcon} alt="" />
        ) : (
          <img src={CloseIcon} alt="" />
        ),
      sortable: false,
    },
    {
      id: "balance",
      name: t("common.balance"),
      
      selector: (row) => {
        if (row.balanceAmount) {
          return <>{formatBalance(row.balanceAmount || 0)}</>;
        } else {
          return 0;
        }
      },
      sortable: false,
    },
    {
      id: "createDate",
      name: t("table.date"),
      
      selector: (row) =>
        (new Date(row.createdAt).toLocaleString() && !isMobile
          ? new Date(row.createdAt).toLocaleString()
          : new Date(row.createdAt).toLocaleString().split(",")?.[0]) || "—",
      sortable: false,
      minWidth: "190px",
    },
    {
      id: "linkTo",
      name: "",
      
      selector: (row) =>
        !isMobile ? (
          <CIcon
            icon={cilSettings}
            onClick={() =>
              !partner
                ? history.push(`/admin/users/${row.id}`)
                : history.push(`/admin/users/${row.id}/products`)
            }
            style={{cursor: 'pointer'}}
          />
        ) : (
          <div
            className="go-to-settings"
            onClick={() =>
              !partner
                ? history.push(`/admin/users/${row.id}`)
                : history.push(`/admin/users/${row.id}/products`)
            }
            style={{cursor: 'pointer'}}
          >
            {t("common.go_to_settings")} <CIcon icon={cilSettings} />
          </div>
        ),
      mobileFooter: true,

      compact: true,
      width: "40px",
      sortable: false,
    },
  ];
  const renderMobileItem = (el, row) => {
    return (
      <div className="mobile-list__line">
        <div className="mobile-list__key">{el.name}</div>
        <div className="mobile-list__value">{el.selector(row)}</div>
      </div>
    );
  };
  return (
    <>
      <div className="orders-table">
        {isLoading && (
          <div className="with-preloader">
            <Preloader />
          </div>
        )}
        {(filters?.length > 0 || sorting?.length > 0) && (
          <>
            <div className="filters-wrapper__items">
              {filters?.length > 0 && (
                <Filters
                  title={title || ""}
                  items={filters}
                  setFilters={(val) => {
                    setFilters(val);
                    setCount(count + 1);
                    if (page == 1 && count > 0) {
                      getUsers(page, perPage, val, activeSorting);
                    } else if (page !== 1 && count > 0) {
                      setPage(1);
                    }
                  }}
                />
              )}
              {sorting?.length > 0 && users?.length > 0 && (
                <>
                  <Sorting
                    items={sorting}
                    setItems={setSorting}
                    active={activeSorting}
                  />
                </>
              )}
            </div>
          </>
        )}
        {!isMobile ? (
          <DataTable
            columns={columns}
            data={users}
            sortIcon={<CIcon icon={cilArrowTop} />}
            noDataComponent={
              <div className="no-data">{t("table.notFound")}</div>
            }
            // onRowClicked={(row) =>
            //   !partner
            //     ? history.push(`/admin/users/${row.id}`)
            //     : history.push(`/admin/users/${row.id}/products`)
            // }
            // progressPending={isLoading}
            // progressComponent={<CSpinner color="primary" />}
          />
        ) : (
          <div className="mobile-list">
            {users?.length > 0 ? (
              <>
                {users.map((row) => {
                  return (
                    <div className="mobile-list__item">
                      {columns.map((el) => {
                        if (el.mobileHeader) {
                          return (
                            <div className="mobile-list__header">
                              {renderMobileItem(el, row)}
                            </div>
                          );
                        }
                      })}
                      <div className="mobile-list__body">
                        {columns.map((el) => {
                          if (!el.mobileFooter && !el.mobileHeader) {
                            return renderMobileItem(el, row);
                          }
                        })}
                      </div>
                      {columns.map((el) => {
                        if (el.mobileFooter) {
                          return (
                            <div
                              className="mobile-list__footer mobile-list__footer_centered"
                              style={{ marginTop: "20px" }}
                            >
                              {renderMobileItem(el, row)}
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="no-data">{t("table.notFound")}</div>
            )}
          </div>
        )}
        {users?.length > 0 && (
          <TablePagination
            page={page}
            setPage={(page) => {
              setPage(page);
            }}
            total={total}
            perPage={perPage}
            setPerPage={(val) => {
              if (page == 1) {
                getUsers(page, val, filters, activeSorting);
              }
              setPerPage(val);
            }}
          />
        )}
      </div>
    </>
  );
}

export default UsersTable;
