import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Header from "components/Header";
import About from "components/About";
import Work from "components/Work";
import Services from "components/Services";
import Footer from "components/Footer";
import Layout from "components/Layout";

function MainPage(props) {
  const { t } = useTranslation();

  return (
    <>
      <About />
    </>
  );
}

export default MainPage;
