import React, { useEffect, useState } from "react";
import "./index.scss";
import Axios from "axios";
import { API_URL } from "utils/settings";
import {
  CAccordion,
  CButton,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CSpinner,
} from "@coreui/react";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import PartnersProductItem from "./productItem";
import Breadcrumbs from "components/Breadcrumbs";
import { formatBalance, renderCurrency } from "utils/utils";
import PayoutModal from "./payoutModal";
import Preloader from "components/Preloader";
import TablePagination from "components/TablePagination";
function PartnersProducts(props) {
  const { currency, id, userInfo, user, callback } = props;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [openedItems, setOpenedItems] = useState([]);
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const getProducts = () => {
    setLoading(true);
    Axios.get(`${API_URL}/admin/partner/${id}/product`).then(function (
      response
    ) {
      if (response.data) {
        const items = response.data.data.map((el) => {
          const percent = el.products.every(
            (item) => item.percent === el.products[0].percent
          );
          return {
            serviceUid: el.serviceUid,
            serviceName: el.serviceName,
            percent: percent ? el.products[0].percent : null,
            products: el.products,
          };
        });
        setData([]);
        setFullData([]);
        setFullData(items);
        setData(items.filter((el, i) => i < 10));
        setTotal(response.data.total);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    if (fullData?.length > 0) {
      setData([]);
      // fullData?.length > 0
      setData(fullData?.slice(perPage * page - perPage, +perPage * page));
      // : setData(
      //     props.item.products?.slice(
      //       perPage * page - perPage,
      //       +perPage * page
      //     )
      //   );
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    setData(fullData?.slice(0, +perPage));
  }, [perPage]);
  useEffect(() => {
    getProducts();
  }, []);
  return (
    <div className="admin-panel__item">
      {userInfo?.email && (
        <h5 className="d-flex justify-content-between align-items-center user-heading">
          {t("heading.partner")} {userInfo?.email}
          <button
            className="primary"
            disabled={parseFloat(user.partner.balance == 0)}
            onClick={() => setShowModal(true)}
          >
            {t("heading.payout")}: {formatBalance(user.partner.balance)}
          </button>
        </h5>
      )}
      <div
        className={classNames({
          "partners-wrapper": true,
          // "with-preloader": loading,
        })}
      >
        {loading && (
          <div className="with-preloader">
            <Preloader />
          </div>
        )}
        <div
          className={classNames({
            partners: true,
          })}
        >
          <CAccordion activeItemKey={openedItems}>
            {data.map((el, i) => {
              return (
                <PartnersProductItem
                  key={`${el.serviceUid}_block`}
                  loading={loading}
                  setLoading={setLoading}
                  item={el}
                  index={i + 1}
                  openedItems={openedItems}
                  setOpenedItems={setOpenedItems}
                  id={id}
                  callback={getProducts}
                />
              );
            })}
          </CAccordion>
        </div>
        {data.length > 0 && (
          <TablePagination
            page={page}
            setPage={setPage}
            total={total}
            perPage={perPage}
            setPerPage={(val) => {
              setPerPage(val);
              // if (page == 1) {
              //   getOrders(page, val, filters, false);
              // }
            }}
          />
        )}
      </div>
      <PayoutModal
        show={showModal}
        setShow={setShowModal}
        balance={user?.partner.balance}
        currency={userInfo?.currency}
        id={id}
        callback={callback}
      />
    </div>
  );
}
export default PartnersProducts;
