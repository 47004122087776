import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cibVk,
  cilPhone,
  cilPen,
  cilX,
  cilCheckAlt,
  cibTelegramPlane,
  cibWhatsapp,
  cibFacebookF,
  cilXCircle,
  cibInstagram,
  cilChevronTop,
  cilCheck,
  cilListFilter,
  cilOptions,
  cilSettings,
} from "@coreui/icons";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { API_URL } from "utils/settings";
import { formatBalance, getDate } from "utils/utils";
import { CSpinner } from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { CTooltip, CButton } from "@coreui/react";
import TablePagination from "components/TablePagination";
import Filters from "components/Filters";
import Sorting from "components/Sorting";
import { useWindowSize } from "utils/utils";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Preloader from "components/Preloader";

function AdminPaymentsTable(props) {
  let history = useHistory();

  const {
    getPayments,
    payments,
    total,
    page,
    setTotal,
    perPage,
    filters,
    setFilters,
    title,
    sorting,
    activeSorting,
    currency,
    setSorting,
    setPage,
    setPerPage,
    isLoading,
    partner,
  } = props;
  const [count, setCount] = useState(0);

  const [showFilters, setShowFilters] = useState(false);
  const windowSize = useWindowSize()[0];
  const isMobile = windowSize < 767;
  const renderDate = (date) => {
    const newDate = new Date(date).toLocaleString();
    return (
      <div>
        <CTooltip content={<>{`${newDate}`}</>}>
          <div>{newDate?.split(",")[0] || "—"}</div>
        </CTooltip>
      </div>
    );
  };
  useEffect(() => {
    getPayments(page, perPage, filters, activeSorting);
  }, [page, activeSorting]);
  useEffect(() => {
    setPage(1);
  }, [perPage]);
  const { t } = useTranslation();
  const columns = [
    {
      id: "id",
      name: t("common.id"),
      center: true,
      selector: (row) => row.id || "—",
      compact: true,

      sortable: false,
    },
    {
      id: "userId",
      name: t("table.user"),
      center: true,
      compact: true,
      selector: (row) =>
        <Link to={`/admin/users/${row.userId}`}>{row.userId}</Link> || "—",
      sortable: false,
    },
    {
      id: "provider",
      name: t("table.provider"),
      center: true,
      selector: (row) => t(`${row.paymentProvider}`) || "—",
      // notMobile: true,
    },
    {
      id: "status",
      name: t("table.status"),
      center: true,
      selector: (row) => (row.status ? t(`table.${row.status}`) : "-"),
    },
    {
      id: "amount",
      name: t("table.amount_with_commission"),
      center: true,
      selector: (row) => <>{formatBalance(row.amount || 0)}</>,
    },
    {
      id: "amount",
      name: t("table.amount"),
      center: true,
      selector: (row) => <>{formatBalance(row.amountOrigin || 0)}</>,
    },
    {
      id: "commission",
      name: t("commission"),
      center: true,
      selector: (row) =>
        row.commission ? `${parseFloat(row.commission).toFixed(2)}%` : `0%`,
      sortable: false,
      // notMobile: true,
    },
    {
      id: "createDate",
      name: t("table.date"),
      center: true,
      selector: (row) => (row.createdAt ? renderDate(row.createdAt) : "—"),
      sortable: false,
      compact: true,
      // minWidth: "190px",
    },
  ];
  const renderMobileItem = (el, row) => {
    return (
      <div className="mobile-list__line">
        <div className="mobile-list__key">{el.name}</div>
        <div className="mobile-list__value">{el.selector(row)}</div>
      </div>
    );
  };
  return (
    <>
      <div className="orders-table">
        {isLoading && (
          <div className="with-preloader">
            <Preloader />
          </div>
        )}
        {(filters?.length > 0 || sorting?.length > 0) && (
          <>
            <div className={`filters-wrapper__items`}>
              {filters?.length > 0 && (
                <Filters
                  title={title || ""}
                  items={filters}
                  setFilters={(val) => {
                    setFilters(val);
                    setCount(count + 1);
                    if (page == 1 && count > 0) {
                      getPayments(page, perPage, val, activeSorting);
                    } else if (page !== 1 && count > 0) {
                      setPage(1);
                    }
                  }}
                />
              )}
              {sorting?.length > 0 && payments?.length > 0 && (
                <Sorting
                  items={sorting}
                  setItems={setSorting}
                  active={activeSorting}
                />
              )}
            </div>
          </>
        )}
        {!isMobile ? (
          <DataTable
            columns={columns}
            data={payments}
            sortIcon={<CIcon icon={cilArrowTop} />}
            noDataComponent={
              <div className="no-data">{t("table.notFound")}</div>
            }
            // progressPending={isLoading}
            // progressComponent={<CSpinner color="primary" />}
          />
        ) : (
          <div className="mobile-list">
            {payments?.length > 0 ? (
              <>
                {payments.map((row) => {
                  return (
                    <div className="mobile-list__item">
                      {columns.map((el) => {
                        if (el.mobileHeader) {
                          return (
                            <div className="mobile-list__header">
                              {renderMobileItem(el, row)}
                            </div>
                          );
                        }
                      })}
                      <div className="mobile-list__body">
                        {columns.map((el) => {
                          if (!el.mobileFooter && !el.mobileHeader) {
                            return renderMobileItem(el, row);
                          }
                        })}
                      </div>
                      {columns.map((el) => {
                        if (el.mobileFooter) {
                          return (
                            <div className="mobile-list__footer">
                              {renderMobileItem(el, row)}
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="no-data">{t("table.notFound")}</div>
            )}
          </div>
        )}
        {payments?.length > 0 && (
          <TablePagination
            page={page}
            setPage={(page) => {
              setPage(page);
            }}
            total={total}
            perPage={perPage}
            setPerPage={(val) => {
              if (page == 1) {
                getPayments(page, val, filters, activeSorting);
              }
              setPerPage(val);
            }}
          />
        )}
      </div>
    </>
  );
}

export default AdminPaymentsTable;
