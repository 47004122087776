import React, { useRef } from "react";
import "./index.scss";
import Multiselect from "multiselect-react-dropdown";
import {
  CButton,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import FilterDatePicker from "./datePicker";
import AsyncSelect from "react-select/async";
import { useLocalStorage } from "utils/utils";
function Filters(props) {
  const { items, setFilters, title } = props;
  const textRef = useRef("");
  const [adminActiveInterval, setAdminActiveInterval] = useLocalStorage(
    "adminActiveOrdersInterval",
    10000
  );

  const { t } = useTranslation();

  const promiseOptions = (inputValue, searchFunc) => {
    return searchFunc(inputValue);
  };

  return (
    <>
      {/* <div className="filters__items"> */}
      {items.map((el) => {
        if (el.hidden) {
          return;
        }
        if (el.type == "date" || el.type == "datetime") {
          return (
            <div>
              <CFormLabel>{t(`table.${el.name}`)}</CFormLabel>
              <FilterDatePicker
                type={el.type}
                defaultValue={el.value}
                callBack={(startDate) => {
                  setFilters(
                    items.map((filt) => {
                      if (filt.name == el.name) {
                        return { ...filt, value: startDate || null };
                      }
                      return filt;
                    })
                  );
                }}
              />
            </div>
          );
        }

        if (el.type == "autocomplete") {
          return (
            <div>
              <CFormLabel>{t(`table.${el.name}`)}</CFormLabel>

              <AsyncSelect
                cacheOptions
                style={{ minWidth: "250px" }}
                isClearable
                placeholder={t(`table.${el.name}`)}
                noOptionsMessage={() => t("table.not_found")}
                loadOptions={(value) =>
                  promiseOptions(value?.trim(), el.searchFunc)
                }
                onChange={(value) => {
                  setFilters(
                    items.map((filt) => {
                      if (filt.name == el.name) {
                        return { ...filt, value: value?.value || null };
                      }
                      return filt;
                    })
                  );
                }}
              />
            </div>
          );
        }
        if (el.type == "string") {
          return (
            <div>
              <CFormLabel>{t(`common.${el.name}`)}</CFormLabel>
              <CInputGroup>
                <CFormInput
                  ref={textRef}
                  style={{ minWidth: "158px" }}
                  placeholder={t(`common.${el.name}`)}
                  onChange={(e) => {
                    if (
                      e.target.value?.length == 0 &&
                      items.filter((filter) => filter.name == el.name)?.[0]
                        ?.value?.length > 0
                    ) {
                      setFilters(
                        items.map((filt) => {
                          if (filt.name == el.name) {
                            return {
                              ...filt,
                              value: textRef.current.value?.trim(),
                            };
                          }
                          return filt;
                        })
                      );
                    } else if (e.target.value?.length > 3) {
                      setFilters(
                        items.map((filt) => {
                          if (filt.name == el.name) {
                            return { ...filt, value: e.target.value?.trim() };
                          }
                          return filt;
                        })
                      );
                    }
                  }}
                />
              </CInputGroup>
            </div>
          );
        }
        if (el.name == "interval") {
          return (
            <div>
              <CFormLabel>{t(`table.${el.name}`)}</CFormLabel>

              <CFormSelect
                onChange={(e) => {
                  if (el.name == "interval") {
                    el.callback(e.target.value);
                    return;
                  }
                }}
                value={adminActiveInterval}
                options={el.items?.map((item) => {
                  return {
                    label: t(`table.${item}`),
                    value: item,
                  };
                })}
              />
            </div>
          );
        }
        return (
          <div>
            <CFormLabel>{t(`table.${el.name}`)}</CFormLabel>

            <CFormSelect
              onChange={(e) => {
                if (el.name == "interval") {
                  el.callback(e.target.value);
                  return;
                }
                setFilters(
                  items.map((filt) => {
                    if (filt.name == el.name) {
                      return { ...filt, value: e.target.value };
                    }
                    return filt;
                  })
                );
                // )
              }}
              value={el.value}
              defaulValue={el.value}
              options={el.items?.map((item) => {
                return {
                  label: t(`table.${item}`),
                  value: item,
                };
              })}
            />
          </div>
        );
      })}
      {/* </div> */}
    </>
  );
}

export default Filters;
