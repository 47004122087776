import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CInputGroup as InputGroup,
  CInputGroupText as InputGroupText,
  CFormInput as Input,
  CFormCheck,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import ModalCloseIcon from "../../assets/images/modal-close.svg";
import Logo from "../../assets/images/logo-sms.png";
import UserIcon from "../../assets/images/userAuth.svg";
import LockIcon from "../../assets/images/lockIcon.svg";
import Axios from "axios";
import { API_URL } from "../../utils/settings";
import { toast } from "react-toastify";
import PasswordValidation from "../PasswordValidation";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ToastComponent from "components/ToastComponent";
function Auth2faModal(props) {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const { show, setShow, callback, showAuthModal, setShowAuthModal } = props;
  const submit = (e) => {
    e.preventDefault();

    Axios.post(`${API_URL}/auth/2fa_check`, {
      oneTimeCode: value,
    })
      .then(function (response) {
        if (response?.data?.token) {
          localStorage.setItem("token", response.data.token);
          window.location.href = "/panel/main";
        }
        if (!response.data.twoFactorComplete) {
          ToastComponent("error", t("common.bad_code"));
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    setValue("");
  }, [show]);
  return (
    <CModal
      visible={show}
      onClose={() => {
        setShow(false);
      }}
    >
      <CModalHeader>
        <img src={Logo} className="modal-logo" alt="" />
        <CModalTitle>{t("2fa.enable")}</CModalTitle>
        <div className="modal-close" onClick={() => setShow(false)}>
          <img src={ModalCloseIcon} alt="" />
        </div>
      </CModalHeader>

      <form onSubmit={(e) => submit(e)}>
        <CModalBody>
          <div className="modal-auth-form">
            <InputGroup>
              <InputGroupText id="user">
                <img src={LockIcon} alt="" />
              </InputGroupText>
              <Input
                placeholder={t("common.six_symbols_code")}
                onChange={(e) => {
                  setValue(e.target.value);
                  // if (loginMailNotify) {
                  //     showLoginMailNotify(false);
                  // }
                }}
                value={value}
                required
                type="text"
                name={"regPass"}
                // autoComplete={false}
                autoComplete="off"
              />
            </InputGroup>
          </div>
        </CModalBody>
        <CModalFooter>
          <button
            className="primary"
            onClick={() => {
              // history.replace({
              //     search: "",
              // });
              // setShow(false);
            }}
          >
            {t("common.submit_form")}
          </button>
        </CModalFooter>
      </form>
    </CModal>
  );
}

export default Auth2faModal;
