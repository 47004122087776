import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { CButton } from "@coreui/react";
import { useTranslation } from "react-i18next";

const ExportExcel = ({ excelData, fileName }) => {
    const { t } = useTranslation();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button className="primary outline" onClick={() => exportToExcel(fileName)}>
      {t("ip.export_excel")}
    </button>
  );
};

export default ExportExcel;
