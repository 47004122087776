import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cilClock,
  cilX,
  cibVk,
  cilCheck,
  cilOptions,
  cilListFilter,
} from "@coreui/icons";
import { CSpinner, CTooltip } from "@coreui/react";

import TablePagination from "components/TablePagination";
import Filters from "components/Filters";
import { formatBalance, useLocalStorage, useWindowSize } from "utils/utils";
import Sorting from "components/Sorting";
import {
  cnCountries,
  enCountries,
  esCountries,
  ruCountries,
} from "components/ChooseCountries/countries";
import Preloader from "components/Preloader";
function TransactionsTable(props) {
  const {
    currency,
    payments,
    page,
    setPage,
    total,
    perPage,
    setPerPage,
    filters,
    setFilters,
    isLoading,
    getPayments,
    sorting,
    activeSorting,
    setSorting,
  } = props;
  const [locale] = useLocalStorage("locale", "gb");

  const countriesObj = {
    ru: ruCountries,
    gb: enCountries,
    cn: cnCountries,
    es: esCountries,
  };
  const actualLanguage = countriesObj[locale];

  const windowSize = useWindowSize()[0];
  const [showFilters, setShowFilters] = useState(false);
  const isMobile = windowSize < 767;

  useEffect(() => {
    setPage(1);
  }, [perPage]);
  useEffect(() => {
    getPayments(page, perPage, filters, activeSorting);
  }, [page, filters, activeSorting]);

  const renderStatus = (status) => {
    return t(`table.${status}`);
  };
  const renderIcon = (iconName, country) => {
    const icons = {
      vk: cibVk,
    };
    if (!icons[iconName]) {
      return `${iconName} - ${country}`;
    } else {
      return (
        <>
          <CIcon icon={icons[iconName]} /> - {country}
        </>
      );
    }
  };
  const renderInfo = (iconName, country, serviceName) => {
    return (
      <div
        className="d-flex align-items-center order-flag-service"
        style={{ height: "17px" }}
      >
        <CTooltip content={<>{serviceName}</>}>
          <div
            className={`bg bg-${iconName}`}
            alt={iconName}
            style={{ transform: "scale(0.5)" }}
          />
        </CTooltip>
        <span className="manrope">></span>
        <CTooltip content={<>{actualLanguage[country]}</>}>
          <div className={`flag flag-${country}`} />
        </CTooltip>
      </div>
    );
  };
  const { t } = useTranslation();
  const columns = [
    {
      id: "id",
      name: t("common.id"),
      
      selector: (row) => row.id || "—",
      notMobile: true,
    },
    {
      id: "info",
      //   name: t("table.service"),
      name: "",
      
      compact: true,
      selector: (row) => (
        <div>
          {row.service && renderInfo(row.service, row.country, row.serviceName)}
        </div>
      ),
      sortable: false,
      notMobile: true,
      // width: "90px",
    },
    {
      id: "createDate",
      name: t("table.date"),
      
      selector: (row) =>
        (new Date(row.createdAt).toLocaleString() && !isMobile
          ? new Date(row.createdAt).toLocaleString()
          : new Date(row.createdAt).toLocaleString().split(",")?.[0]) || "—",
      notMobile: true,
    },
    {
      id: "serviceUid",
      name: t("table.action"),
      
      selector: (row) => {
        return (
          <div>
            {t(`table.${row.action}`) || "—"}{" "}
            {row.orderId && (
              <span className="hidden_sm">ID: {row.orderId}</span>
            )}
          </div>
        );
      },
    },

    {
      id: "provider",
      name: t("table.provider"),
      
      selector: (row) => t(`${row.provider}`) || "—",
      notMobile: true,
    },
    {
      id: "description",
      name: t("table.description"),
      
      selector: (row) => row.description || "—",
    },
    {
      id: "amount",
      name: t("table.amount"),
      
      selector: (row) => <>{formatBalance(row.amount)}</>,
    },
    {
      id: "status",
      name: t("table.state"),
      
      selector: (row) => (row.state ? t(`table.${row.state}`) : "-"),
    },
  ];
  const renderMobileItem = (el, row) => {
    return (
      <div className="mobile-list__line">
        <div className="mobile-list__key">{el.name}</div>
        <div className="mobile-list__value">{el.selector(row)}</div>
      </div>
    );
  };
  return (
    <>
      <div className="orders-table">
        {isLoading && (
          <div className="with-preloader">
            <Preloader />
          </div>
        )}
        <div className="orders-table">
          {(filters?.length > 0 || sorting?.length > 0) && (
            <>
              <div className="filters-wrapper__items">
                {filters?.length > 0 && (
                  <Filters
                    items={filters}
                    setFilters={setFilters}
                    // title={`${t("heading.payments")} — ${total}`}
                  />
                )}
                {sorting?.length > 0 && (
                  <>
                    <Sorting
                      items={sorting}
                      setItems={setSorting}
                      active={activeSorting}
                    />
                  </>
                )}
              </div>
            </>
          )}
          {!isMobile ? (
            <DataTable
              columns={columns}
              data={payments}
              sortIcon={<CIcon icon={cilArrowTop} />}
              // progressPending={isLoading}
              progressComponent={<CSpinner color="primary" />}
              noDataComponent={
                <div className="no-data">{t("table.notFound")}</div>
              }
            />
          ) : (
            <div className="mobile-list">
              {payments?.length > 0 ? (
                <>
                  {payments.map((row) => {
                    return (
                      <div className="mobile-list__item">
                        {columns.map((el) => {
                          if (el.mobileHeader) {
                            return (
                              <div className="mobile-list__header">
                                {renderMobileItem(el, row)}
                              </div>
                            );
                          }
                        })}
                        <div className="mobile-list__body">
                          {columns.map((el) => {
                            if (!el.mobileFooter && !el.mobileHeader) {
                              return renderMobileItem(el, row);
                            }
                          })}
                        </div>
                        {columns.map((el) => {
                          if (el.mobileFooter) {
                            return (
                              <div className="mobile-list__footer">
                                {renderMobileItem(el, row)}
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="no-data">{t("table.notFound")}</div>
              )}
            </div>
          )}
          {payments?.length > 0 && (
            <TablePagination
              page={page}
              setPage={setPage}
              total={total}
              perPage={perPage}
              setPerPage={(val) => {
                setPerPage(val);
                if (page == 1) {
                  getPayments(page, val, filters, null);
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default TransactionsTable;
