import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cibVk,
  cilPhone,
  cilPen,
  cilX,
  cilCheckAlt,
  cibTelegramPlane,
  cibWhatsapp,
  cibFacebookF,
  cilXCircle,
  cibInstagram,
  cilChevronTop,
  cilInfo,
  cilChevronCircleRightAlt,
  cilOptions,
  cilListFilter,
  cilLoopCircular,
  cilReload,
} from "@coreui/icons";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { formatBalance, getDate, useLocalStorage } from "utils/utils";
import { CSpinner } from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { CTooltip, CButton } from "@coreui/react";
import TablePagination from "components/TablePagination";
import Filters from "components/Filters";
import Sorting from "components/Sorting";
import { useWindowSize } from "utils/utils";

import {
  cnCountries,
  enCountries,
  esCountries,
  ruCountries,
} from "components/ChooseCountries/countries";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { confirmAlert } from "react-confirm-alert";
import Preloader from "components/Preloader";
import EyeIcon from "../../assets/images/eye.svg";
function OrderTable(props) {
  const {
    getOrders,
    orders,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    isLoading,
    filters,
    title,
    sorting,
    activeSorting,
    setSorting,
    setFilters,
    setIsLoading,
    currency,
    updateUser,
    needCancel,
    needUser = false,
    needResend = false,
    withoutPerPage = false,
  } = props;

  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const windowSize = useWindowSize()[0];
  const [locale] = useLocalStorage("locale", "gb");

  const [count, setCount] = useState(0);
  const isMobile = windowSize < 991;
  const countriesObj = {
    ru: ruCountries,
    gb: enCountries,
    cn: cnCountries,
    es: esCountries,
  };
  const actualLanguage = countriesObj[locale];

  useEffect(() => {
    getOrders(page, perPage, filters, false);
  }, [page, activeSorting]);
  useEffect(() => {
    setPage(1);
  }, [perPage, filters]);
  useEffect(() => {
    if (
      orders.filter(
        (el) => el.status == "waiting_phone" || el.status == "waiting_sms"
      )?.length > 0
    ) {
      const interval = setInterval(() => {
        getOrders(page, perPage, filters, false);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, []);

  const renderStatus = (status) => {
    const statusIcons = {
      waiting_phone: cilPhone,
      waiting_sms: cilPen,
      canceled: cilX,
      finished: cilCheckAlt,
    };

    if (statusIcons[status]) {
      return (
        <CTooltip content={<>{t(`table.${status}`)}</>}>
          <CIcon icon={statusIcons[status]} />
        </CTooltip>
      );
    } else {
      return status;
    }
  };
  const renderSms = (smsContent) => {
    if (smsContent) {
      return (
        <CTooltip content={<>{smsContent}</>}>
          <div>{smsContent ? <img src={EyeIcon} /> : "-"}</div>
        </CTooltip>
      );
    }
    return "—";
  };
  const getOrderPrice = (oId) => {
    setLoadingId(oId);
    setIsLoading(true);
    Axios.get(`${API_URL}/order/price`, {
      params: {
        orderId: oId,
      },
    })
      .then(function (response) {
        if (response.data.canRepeatSms) {
          setLoadingId("");
          setIsLoading(false);
          if (parseFloat(response.data.amount) > 0) {
            repeatAmountInfo(oId, response.data.amount, response.data.currency);
          } else {
            orderRepeat(oId, response.data.amount, response.data.currency);
          }
        } else {
          ToastComponent("error", t("order.cantRepeatSms"));
        }
      })
      .catch((error) => {
        setLoadingId("");
        setIsLoading(false);
      });
  };
  const orderRepeat = (oId, repeatAmount, repeatCurrency) => {
    setLoadingId(oId);
    setIsLoading(true);
    Axios.post(`${API_URL}/order/repeat`, {
      orderId: oId,
      amount: repeatAmount,
      currency: repeatCurrency,
    })
      .then(function (response) {
        // if (response.data.status == "error") {
        //   ToastComponent("error", response.data.message);
        // } else {
        //   ToastComponent("success", t("table.order_create"));
        // }
        // if (response.data.orderId) {
        //   getOrders(page, perPage, filters);
        ToastComponent(
          response?.data?.status,
          t(`order.repeat_${response.data.status}`)
        );
        updateUser();
        setIsLoading(false);
        setLoadingId("");
        // }
      })
      .catch((error) => {
        setIsLoading(false);
        setLoadingId("");
      });
  };
  const repeatAmountInfo = (oId, repeatAmount, repeatCurrency) => {
    confirmAlert({
      title: `${t("order.repeatOrderTitle")}${formatBalance(
        repeatAmount || 0
      )}`,
      message: t("order.repeatOrderSubtitle"),
      buttons: [
        {
          label: t("common.yes"),
          onClick: () => orderRepeat(oId, repeatAmount, repeatCurrency),
        },
        {
          label: t("common.no"),
          onClick: () => console.log("no"),
        },
      ],
    });
  };
  const renderIcon = (iconName, country, serviceName) => {
    return (
      <div className="d-flex align-items-center order-flag-service">
        <CTooltip content={<>{serviceName}</>}>
          <div className={`bg bg-${iconName}`} alt={iconName} />
        </CTooltip>
        <span className="manrope">></span>
        <CTooltip content={<>{actualLanguage[country]}</>}>
          <div className={`flag flag-${country}`} />
        </CTooltip>
      </div>
    );
  };
  const cancelOrder = (id) => {
    setLoading(true);
    setLoadingId(id);
    Axios.put(`${API_URL}/order`, {
      orderId: id,
    })
      .then(function (response) {
        if (response.data.orderId) {
          ToastComponent("success", t("table.order_cancel"));
          getOrders(page, perPage, filters, false);
          setLoading(false);
          updateUser();
          setLoadingId("");
        }
      })
      .catch((error) => {
        setLoading(false);
        updateUser();
        setLoadingId("");
      });
  };
  const renderDate = (date) => {
    const newDate = new Date(date).toLocaleString();
    return (
      <div>
        <CTooltip content={<>{`${newDate}`}</>}>
          <div>{newDate || "—"}</div>
        </CTooltip>
      </div>
    );
  };
  const { t } = useTranslation();
  const columns = [
    {
      // compact: true,
      id: "phone",
      allowOverflow: true,
      name: t("common.phone"),
      // center: true,
      selector: (row) =>
        (
          <div
            className="d-flex align-items-center "
            // style={{ width: "170px" }}
          >
            <div>
              {row.serviceUid &&
                renderIcon(row.serviceUid, row.countryAlpha2, row.serviceName)}
            </div>
            <div className="phone" style={{ marginLeft: "10px" }}>
              {row.phone ? `+${row.phone}` : t("table.not_number")}
            </div>
          </div>
        ) || t("table.not_number"),
      sortable: false,
      // width: "170px",
      grow: 2,
      // copmpact: true,
    },

    {
      id: "status",
      name: t("table.status"),
      // center: true,
      selector: (row) =>
        row.status ? (
          <>
            <CTooltip content={<>{t(`table.${row.status}`)}</>}>
              <div>{t(`table.${row.status}`)}</div>
            </CTooltip>
          </>
        ) : (
          "—"
        ),
      sortable: false,
    },
    {
      id: "amount",
      name: t("table.amount"),
      // center: true,
      selector: (row) => {
        if (row.amount) {
          return formatBalance(row.amount || 0);
        } else {
          return 0;
        }
      },
      sortable: false,
    },
    {
      id: "sms_content",
      name: t("table.sms_content"),
      wrap: true,
      // center: true,
      // minWidth: "80px",
      height: "100%",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {renderSms(row.smsContent)}{" "}
          {needResend && row.status == "finished" && (
            <div
              className="resend"
              onClick={() => getOrderPrice(row.mainOrderId || row.id)}
            >
              <CIcon icon={cilReload} />
            </div>
          )}
        </div>
      ),
    },
    {
      id: "createDate",
      name: t("table.date"),
      // center: true,
      selector: (row) => (row.createdAt ? renderDate(row.createdAt) : "—"),
      sortable: false,
      // minWidth: "80px",
      notMobile: true,
      grow: 2,
    },
  ];
  needCancel &&
    columns.push({
      id: "order_action",
      name: "",
      // center: true,

      selector: (row) => {
        if (row.status == "waiting_sms") {
          return (
            <button
              onClick={() => cancelOrder(row.id)}
              disabled={loading && loadingId == row.id}
              className="error"
            >
              {t("confirm.cancel")}
            </button>
          );
        }
      },
      sortable: false,
      // width: "60px",
      compact: true,
    });
  needUser &&
    columns.unshift({
      id: "userId",
      name: t(`table.user`),
      // center: true,
      selector: (row) => (
        <CTooltip content={<>{`${row.user.email || "—"}`}</>}>
          {row.user.id ? (
            <a
              href={`/admin/users/${row.user.id}`}
              target="_blank"
              style={{ color: "#051229", textDecoration: "none" }}
            >
              {row.user?.email || row.user?.id}
            </a>
          ) : (
            <div>{row.user.id || "—"}</div>
          )}
        </CTooltip>
      ),
      sortable: false,
      notMobile: true,
      grow: 2,
    });
  columns.unshift({
    id: "id",
    name: t("common.id"),
    // center: true,
    selector: (row) => {
      return row.id ? (
        <div
          className="order-id"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <b>{row.id}</b>
          {row.mainOrderId && (
            <small style={{ fontSize: "10px" }}>
              (
              <CIcon
                icon={cilReload}
                style={{
                  transform: "scale(0.6)",
                  marginBottom: "-2px",
                  marginRight: "-2px",
                  marginLeft: "-2px",
                }}
              />
              {row.mainOrderId})
            </small>
          )}
        </div>
      ) : (
        "—"
      );
    },
    sortable: false,
    // compact: true,
    notMobile: true,
  });

  const renderMobileItem = (el, row) => {
    return (
      <div className="mobile-list__line">
        <div className="mobile-list__key">{el.name}</div>
        <div className="mobile-list__value">{el.selector(row)}</div>
      </div>
    );
  };
  return (
    <>
      <div className="orders-table">
        {isLoading && (
          <div className="with-preloader">
            <Preloader />
          </div>
        )}
        {title && <h6 className="hidden_sm hidden_md">{title}</h6>}
        {(filters?.length > 0 || sorting?.length > 0) && (
          <>
            <div className="d-flex justify-content-between align-items-center w-100 csv-wrapper">
              <div className="filters-wrapper__items">
                {filters?.length > 0 && (
                  <>
                    <Filters
                      items={filters}
                      setFilters={(val) => {
                        setFilters(val);
                        setCount(count + 1);
                        if (page == 1 && count > 0) {
                          getOrders(page, perPage, val, false);
                        }
                      }}
                    />
                  </>
                )}
                {sorting?.length > 0 && (
                  <>
                    <Sorting
                      items={sorting}
                      setItems={setSorting}
                      active={activeSorting}
                    />
                  </>
                )}
              </div>
              {props.needCsv && (
                <button
                  onClick={() => getOrders(page, perPage, filters, true)}
                  className="primary csv"
                >
                  {t("ip.export_csv")}
                </button>
              )}
            </div>
          </>
        )}
        <div className="table-wrapper-block">
          {!isMobile ? (
            <DataTable
              columns={columns}
              data={orders}
              sortIcon={<CIcon icon={cilArrowTop} />}
              noDataComponent={
                <div className="no-data">{t("table.notFound")}</div>
              }
              // progressPending={isLoading}
              progressComponent={<CSpinner color="primary" />}
            />
          ) : (
            <div className="mobile-list">
              {orders?.length > 0 ? (
                <>
                  {orders.map((row) => {
                    return (
                      <div className="mobile-list__item">
                        {columns.map((el) => {
                          if (el.mobileHeader) {
                            return (
                              <div className="mobile-list__header">
                                {renderMobileItem(el, row)}
                              </div>
                            );
                          }
                        })}
                        <div className="mobile-list__body">
                          {columns.map((el) => {
                            if (!el.mobileFooter && !el.mobileHeader) {
                              return renderMobileItem(el, row);
                            }
                          })}
                        </div>
                        {columns.map((el) => {
                          if (el.mobileFooter) {
                            return (
                              <div className="mobile-list__footer">
                                {renderMobileItem(el, row)}
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="no-data">{t("table.notFound")}</div>
              )}
            </div>
          )}
        </div>
        {orders?.length > 0 && (
          <>
            <TablePagination
              page={page}
              setPage={setPage}
              total={total}
              perPage={perPage}
              withoutPerPage={withoutPerPage}
              setPerPage={(val) => {
                setPerPage(val);
                if (page == 1) {
                  getOrders(page, val, filters, false);
                }
              }}
            />
          </>
        )}
      </div>
    </>
  );
}

export default OrderTable;
