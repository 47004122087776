import React, { useContext, useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar";
import { CButton, CCol, CFormLabel, CRow, CSpinner } from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "core/contexts";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import Axios from "axios";
import { API_URL } from "utils/settings";
import ExportExcel from "components/ExcelExport";
import CsvDownloader from "react-csv-downloader";
import exportJson from "components/ExportJson";
import DatePicker from "react-datepicker";
import _ from "lodash";

function MailsFromPhone({ handler, method = "/admin/log/email/phone" }) {
  const [phoneLog, setPhoneLog] = useState([]);
  const [phonesList, setPhonesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const getPhoneLog = () => {
    const params = {};
    if (phonesList?.length > 0) {
      params["phoneList"] = phonesList;
    } else {
      toast.error(t("ip.phone_error"));
      return;
    }
    setLoading(true);
    setPhoneLog([]);
    Axios.get(`${API_URL}${method}`, {
      params: params,
    })
      .then(function (response) {
        if (response.data.emails) {
          setPhoneLog(
            response.data.emails.map((el) => {
              return { email: el };
            })
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <div className="">
      <CCol>
        <CFormLabel>{t("ip.phone")}</CFormLabel>
        <TagsInput
          value={phonesList}
          onChange={(value) => {
            setPhonesList(
              handler(value).map((el) => el.replace(/[^a-zA-Z0-9]/g, ""))
            );
          }}
          onBlur={(event) => {
            if (event.target.value?.length > 1) {
              setPhonesList(
                handler([...phonesList, ...event.target.value.split(" ")])
              );
              event.target.value = "";
            }
          }}
          autocomplete="off"
          name="logswith"
          separators={["Enter", " ", "Tab"]}
        />
      </CCol>
      <br />
      <div className="ips-interval">
        <div
          className="d-flex justify-content-end w-100"
          style={{ columnGap: "15px" }}
        >
          <button
            className="primary"
            onClick={() => getPhoneLog()}
            disabled={loading}
          >
            {loading && (
              <>
                <CSpinner size="sm" color="white" />
                &nbsp;
              </>
            )}
            {t("ip.get_data")}
          </button>
          {phoneLog?.length > 0 && (
            <div className="ips-buttons">
              <CsvDownloader
                filename="mailsFromPhones"
                extension=".csv"
                separator=";"
                wrapColumnChar="'"
                columns={[
                  {
                    id: "email",
                    displayName: "Email",
                  },
                ]}
                datas={phoneLog}
              >
                <button className="primary outline">
                  {t("ip.export_csv")}
                </button>
              </CsvDownloader>
              <ExportExcel excelData={phoneLog} fileName="mailsFromPhones" />
              <button
                className="primary outline"
                onClick={() => exportJson(phoneLog, "mailsFromPhones.json")}
              >
                {t("ip.export_json")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MailsFromPhone;
